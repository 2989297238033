import React from "react";
import { IconsProps } from "./Icons";

export const AnnotationIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 14 12"}
      width={width ? width : "14"}
      height={height ? height : "12"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M6.95172 11.5862V9.94483L11.0455 5.85103L12.6869 7.49241L8.5931 11.5862H6.95172ZM0 7.72414V6.17931H5.4069V7.72414H0ZM13.2276 6.95172L11.5862 5.31034L12.1462 4.75034C12.2878 4.60874 12.468 4.53793 12.6869 4.53793C12.9057 4.53793 13.086 4.60874 13.2276 4.75034L13.7876 5.31034C13.9292 5.45195 14 5.63218 14 5.85103C14 6.06988 13.9292 6.25011 13.7876 6.39172L13.2276 6.95172ZM0 4.63448V3.08966H8.49655V4.63448H0ZM0 1.54483V0H8.49655V1.54483H0Z"
        fill="currentColor"
      />
    </svg>
  );
};
