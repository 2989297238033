import React from "react";
import { Row, Col } from "antd";
import "./ProjectSpecificInsights.scss";
import {
  CancerStagingTile,
  NSCLCDiagnosisTile,
  PdStatusTile,
  PembrolizumabTherapyTile,
  GeneMutationTile,
} from "./project-specific-tiles";
import { BloodBasedBiomarkerCard, RiskFactorCard } from "./patient-insight-tiles";
import { Biomarker, ProjectSpecificTiles } from "../../../interfaces";
import { BaseEntity } from "../../../api";

interface Props {
  projectSpecific: ProjectSpecificTiles;
  patientId: number;
  threshold_confidence_score: string[];
  biomarkers: Biomarker[];
  riskFactors: BaseEntity[];
}

export const ProjectSpecificInsightTiles = ({
  projectSpecific,
  patientId,
  threshold_confidence_score,
  biomarkers,
  riskFactors,
}: Props) => {
  const { cancer_staging, gene_mutations, nslc_cancer, pdl1_status, pembrolizumab_therapy } =
    projectSpecific;
  const threshold =
    threshold_confidence_score && threshold_confidence_score.length > 0
      ? Number(threshold_confidence_score[0])
      : 80;

  return (
    <div className="project-specific  m-t">
      {/* <div className="project-specific-title">
        <p>Project Specific Insights</p>
      </div> */}
      <Row gutter={[14, 28]} className="profile-info-grid">
        <Col className="gutter-row" span={24}>
          {nslc_cancer && (
            <NSCLCDiagnosisTile
              nsclcCancer={nslc_cancer.data}
              patientId={patientId}
              threshold={threshold}
              // threshold={nslc_cancer.confidence_score_threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {gene_mutations && (
            <GeneMutationTile
              geneMutations={gene_mutations.data}
              patientId={patientId}
              threshold={threshold}
              // threshold={gene_mutations.confidence_score_threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={9}>
          {cancer_staging && (
            <CancerStagingTile
              cancerStaging={cancer_staging.data}
              patientId={patientId}
              threshold={threshold}
              // threshold={cancer_staging.confidence_score_threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={9}>
          {pdl1_status && (
            <PdStatusTile
              pdl1Status={pdl1_status.data}
              patientId={patientId}
              threshold={threshold}
              // threshold={pdl1_status.confidence_score_threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={6}>
          {pembrolizumab_therapy && (
            <PembrolizumabTherapyTile
              pembrolizumabTherapy={pembrolizumab_therapy.data}
              patientId={patientId}
              threshold={threshold}
              // threshold={pdl1_status.confidence_score_threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={9}>
          {biomarkers && (
            <BloodBasedBiomarkerCard
              data={biomarkers}
              patientId={patientId}
              threshold={threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={9}>
          {riskFactors && (
            <RiskFactorCard riskFactors={riskFactors} patientId={patientId} threshold={threshold} />
          )}
        </Col>
      </Row>
    </div>
  );
};
