import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Button, Select } from "antd";
import { BackButton } from "../../../components";
import { CATEGORY, EXPORT_OPTIONS } from ".";
import "./annotation.scss";
import { AnnotationProjectFile } from "../../../interfaces";
import { ANNOTATION_FILE_STATUS } from "../../../utils";

interface Props {
  category: string;
  setCategory: Dispatch<SetStateAction<string>>;
  tag: string;
  setTag: Dispatch<SetStateAction<string>>;
  setTagColor: Dispatch<SetStateAction<string>>;
  onAnnotationsSave: () => Promise<void>;
  onAnnotationComplete: () => Promise<any>;
  onAnnotationsExport: (exportAs: string) => Promise<void>;
  fileData: AnnotationProjectFile;
}

export const AnnotateFileHeader = (props: Props) => {
  const {
    category,
    setCategory,
    setTag,
    setTagColor,
    tag,
    onAnnotationsSave,
    onAnnotationsExport,
    onAnnotationComplete,
    fileData
  } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [exportAs, setExportAs] = useState(EXPORT_OPTIONS[0].id);
  const [isComplete, setIsComplete] = useState(false);
  const subCategoryOptions = useMemo(() => {
    const filterCategory = CATEGORY.filter((item) => item.id === category);
    if (filterCategory.length > 0) return filterCategory[0].options;
    else return [];
  }, [category]);

  useEffect(()=>{
    if(fileData?.FileStatus === ANNOTATION_FILE_STATUS.Completed) setIsComplete(true)
  },[fileData])

  const handleCategoryChange = (selectedCategory: string) => {
    setCategory(selectedCategory);
    const categoryIndex = CATEGORY.findIndex((item) => item.id === selectedCategory);
    if (categoryIndex !== -1) {
      setTagColor(CATEGORY[categoryIndex].color);
      setTag(CATEGORY[categoryIndex].options[0].id);
    }
  };

  const handleSubCategoryChange = (selectedSubCategory: string) => {
    setTag(selectedSubCategory);
  };

  const handleExportChange = (option: string) => {
    setExportAs(option);
  };

  const onExport = async () => {
    await onAnnotationsExport(exportAs);
  };

  const onSave = async () => {
    setSaveLoading(true);
    await onAnnotationsSave();
    setSaveLoading(false);
  };

  const onComplete = async () => {
    setCompleteLoading(true);
    const res = await onAnnotationComplete() as any;
    if(res.status === 200){
      setIsComplete(true)
    }
    setCompleteLoading(false);
  };
  
  return (
    <>
      <BackButton />
      <div className="annotation-header flex aic jcsb gp">
        <div className="flex aic gp">
          <div className="flex gp-10 aic">
            <p>Category : </p>
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              className="ai-select"
              value={category}
              style={{ width: 240 }}
              onChange={handleCategoryChange}
              options={CATEGORY.map((item) => ({ value: item.id, label: item.name }))}
            />
          </div>
          <div className="flex gp-10 aic">
            <p>Label : </p>
            <Select
              className="ai-select"
              getPopupContainer={(trigger) => trigger.parentNode}
              value={tag}
              style={{ width: 240 }}
              onChange={handleSubCategoryChange}
              options={subCategoryOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </div>
        </div>
        <div className="flex gp-40 aic">
          <div className="flex gp p-block aic">
            <p>Export as: </p>
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              className="ai-select"
              value={exportAs}
              style={{ width: 120 }}
              onChange={handleExportChange}
              options={EXPORT_OPTIONS.map((item) => ({ value: item.id, label: item.id }))}
            />
            <Button className="outline" onClick={onExport}>
              Export
            </Button>
          </div>
          <Button className="outline" onClick={onSave} loading={saveLoading}>
            Save
          </Button>
          <Button className="outline" onClick={onComplete} loading={completeLoading} disabled={isComplete}> {!isComplete ? 'Complete' : 'Completed'}</Button>
        </div>
      </div>
    </>
  );
};
