import React from "react";
import { InfiniteScrollWrapper } from "./InfiniteScrollWrapper";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TablePaginationConfig } from "antd/es/table";
import { GetComponentProps } from "rc-table/lib/interface";

interface Props {
  dataSource: any[];
  columns: ColumnsType<any>;
  onUpdate: () => Promise<void>;
  pagination: false | TablePaginationConfig;
  hasMore: boolean;
  onRow?: GetComponentProps<any> | undefined;
  loading?: boolean;
}

export const InfiniteScrollTable = ({
  columns,
  dataSource,
  onUpdate,
  pagination,
  hasMore,
  onRow,
  loading,
}: Props) => {
  return (
    <>
      <InfiniteScrollWrapper
        isInfiniteScrollOn={true}
        dataLength={dataSource.length}
        onUpdate={onUpdate}
        hasMore={hasMore}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          onRow={onRow}
          rowClassName={onRow ? "cursor-pointer" : ""}
          loading={loading}
        />
      </InfiniteScrollWrapper>
    </>
  );
};
