import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientPaginationMapping } from "../../api";
import { IUser } from "../../interfaces";

interface PatientSlice {
  list: PatientPaginationMapping[];
}

export const patientSliceInitialState: PatientSlice = {
  list: [] as PatientPaginationMapping[],
};

export const patientSlice = createSlice({
  name: "patient",
  initialState: patientSliceInitialState,
  reducers: {
    setPatientList: (
      state: PatientSlice,
      { payload }: PayloadAction<PatientPaginationMapping[]>
    ) => {
      state.list = [...payload];
    },
  },
});

export const { setPatientList } = patientSlice.actions;

export default patientSlice.reducer;
