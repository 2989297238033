import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  RootState,
  closeModal,
  updateAssignPatientsList,
  setIsProjectAssigned,
} from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { AddPatientsToProject } from "./AddPatientsToProject";
import { Project } from "../../../interfaces";

type StateProps = {
  visibility: boolean;
  clientId: number;
  projects: Project[];
  patientIdsToBeAssigned: number[];
  assignedToAllProjects: boolean;
};

type DispatchProps = {
  handleClose: () => void;
  handleSuccess: () => void;
};
export type AddPatientsToProjectProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  return {
    visibility: state.ui.modal[MODAL_TYPE.ADD_PATIENTS_TO_PROJECT],
    clientId: state.user.user.clientId,
    patientIdsToBeAssigned: state.project.assignPatients.patientIds,
    projects: state.data.projects.list.filter(
      (item) => !state.project.assignPatients.alreadyAssignedProjects.includes(item.Id)
    ),
    assignedToAllProjects:
      state.data.projects.list.length ===
      state.project.assignPatients.alreadyAssignedProjects.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(updateAssignPatientsList({ alreadyAssignedProjects: [], patientIds: [] }));
    dispatch(closeModal(MODAL_TYPE.ADD_PATIENTS_TO_PROJECT));
    setTimeout(() => dispatch(setIsProjectAssigned(false)), 1000);
  },
  handleSuccess: () => {
    dispatch(setIsProjectAssigned(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientsToProject);
