import { Card, Col, Progress, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { createSearchParams, NavLink, useNavigate } from "react-router-dom";
//import "./insightdetail.scss";
import Locations from "../assets/images/Locations.png";
import { GenderIcon } from "../assets/icons/GenderIcon";
import { FemaleIcon } from "../assets/icons/FemaleIcon";
import { OldPeopleIcon } from "../assets/icons/OldPeopleIcon";
import { UnderScoreIcon } from "../assets/icons";
import { PatientListInfo, PatientProfileService } from "../api";
import { FitStatus, PAGE_URL } from "../utils";

const { Column } = Table;
// study desc
interface StudyDescDataType {
  key: string;
  conditiondisease: string;
  interventiontreatment: string;
  phases: string;
}

const studydesccolumns: ColumnsType<StudyDescDataType> = [
  {
    title: "Condition or Disease",
    dataIndex: "conditiondisease",
    key: "conditiondisease",
  },
  {
    title: "Intervention/Treatment",
    dataIndex: "interventiontreatment",
    key: "interventiontreatment",
  },
  {
    title: "Phases",
    dataIndex: "phases",
    key: "phases",
  },
];

const studydescdata: StudyDescDataType[] = [
  {
    key: "1",
    conditiondisease: "Cancer",
    interventiontreatment: "Diagnostic Test: Freenome Test",
    phases: "Not Applicable",
  },
];

// groups and cohorts
interface GroupCohortsDataType {
  key: string;
  groupscohorts: string;
  interventiontreatment: string;
}

const groupcohortscolumns: ColumnsType<GroupCohortsDataType> = [
  {
    title: "Groups / Cohorts",
    dataIndex: "groupscohorts",
    key: "groupscohorts",
    width: "50%",
  },
  {
    title: "Intervention/Treatment",
    dataIndex: "interventiontreatment",
    key: "interventiontreatment",
    width: "50%",
  },
];

const groupcohortsdata: GroupCohortsDataType[] = [
  {
    key: "1",
    groupscohorts: "Collect Subject Data Collect Blood Specimen",
    interventiontreatment:
      "Participants who provide informed consent, meet the eligibility criteria and provide a blood sample for this study will be enrolled.",
  },
  {
    key: "2",
    groupscohorts: "Data Collect Blood Specimen One Year Follow Up On Cancer Status",
    interventiontreatment:
      "Participants who provide informed consent, meet the eligibility criteria and provide a blood sample for this study will be enrolled.",
  },
];

// patient reminder
interface PatientRemainderDataType {
  key: string;
  patientname: string;
  id: number;
  exclusioninclusioncriteria: string[];
  demographicfit: string[];
  geographicfit: string[];
  conditionfit: string[];
  procedurefit: string[];
  physicalactivityfit: string[];
}

const getClass = (value: string) => {
  switch (value.toLowerCase()) {
    case FitStatus.HIGH:
      return "green";
    case FitStatus.MEDIUM:
      return "orange";
    case FitStatus.LOW:
      return "red";
    default:
      return "green";
  }
};

const InsightDetail = () => {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([] as PatientListInfo[]);
  useEffect(() => {
    const fetchPatientsList = async () => {
      const { data } = await PatientProfileService.getPatientsList();
      setPatientList(data.list);
    };
    fetchPatientsList();
  }, []);

  const handleRowClick = (id: number) => {
    navigate({
      pathname: PAGE_URL.PATIENT_PROFILE,
      search: createSearchParams({
        patientId: id.toString(),
      }).toString(),
    });
  };

  return (
    <div className="insight-wrapper">
      {/* First Block */}
      <div className="insight-three-block">
        <h2 className="insightdetail-title">
          Development of Multiomics Blood Tests for Cancer Screening
        </h2>
        <Row className="sponsor">
          <Col span={8}>
            <p className="insight-three-block-title">Sponsor:</p>
            <h4 className="insight-three-block-info">Freenome Holdings Inc.</h4>
          </Col>
          <Col span={8}>
            <p className="insight-three-block-title">Collaborators:</p>
            <h4 className="insight-three-block-info">The City College of New York Aptima, Inc.</h4>
          </Col>
          <Col span={8}>
            <p className="insight-three-block-title">
              Information provided by (Responsible Party):
            </p>
            <h4 className="insight-three-block-info">Freenome Holdings Inc.</h4>
          </Col>
        </Row>
      </div>

      {/* study status */}
      {/* <Row gutter={20} className="study-desc">
        <Col span={5}>
          <h4 className="patient-card-title">Study Status</h4>
          <Card>
            <Progress
              strokeWidth={10}
              className="study-progress"
              type="circle"
              percent={14}
              strokeColor="#4743DB"
              trailColor="#6B6565"
            />
            <div className="gender-stats-status study-status">
              <div className="gender-stats-iconlist">
                <i className="circle yellow"></i>
                Reviewed good fit: 325
              </div>
              <div className="gender-stats-iconlist">
                <i className="circle green"></i>
                Recruited: 178
              </div>
              <div className="gender-stats-iconlist">
                <i className="circle red"></i>
                Reviewed not a fit: 236
              </div>
              <div className="gender-stats-iconlist">
                <i className="circle blue"></i>
                Not Reviewed: 960
              </div>
            </div>
          </Card>
        </Col>
        <Col span={9}>
          <h4 className="patient-card-title">Diversity of Cohort</h4>
          <Card>
            <div className="gender-stats-wrapper">
              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={5}
                  strokeColor="#FF0018"
                  trailColor="#6B6565"
                />
                <FemaleIcon width={17} height={28} fill="#fff" />
                <span className="gender-stats-info">Female</span>
              </div>

              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={25}
                  strokeColor="#E8AE55"
                  trailColor="#6B6565"
                />
                <GenderIcon width={17} height={28} fill="#fff" />
                <span className="gender-stats-info">Hispanic</span>
              </div>

              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={50}
                  strokeColor="#E8AE55"
                  trailColor="#6B6565"
                />
                <GenderIcon width={17} height={28} fill="#fff" />
                <span className="gender-stats-info">White</span>
              </div>

              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={10}
                  strokeColor="#FF0018"
                  trailColor="#6B6565"
                />
                <OldPeopleIcon width={17} height={28} fill="#fff" />
                <span className="gender-stats-info align">
                  <UnderScoreIcon className="underScore" /> 65 years
                </span>
              </div>

              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={23}
                  strokeColor="#E8AE55"
                  trailColor="#6B6565"
                />
                <GenderIcon width={17} height={28} fill="#000" />
                <span className="gender-stats-info">Black</span>
              </div>

              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={65}
                  strokeColor="#44C658"
                  trailColor="#6B6565"
                />
                <GenderIcon width={17} height={28} fill="#fff" />
                <span className="gender-stats-info">United States</span>
              </div>

              <div className="gender-stats">
                <Progress
                  width={45}
                  strokeWidth={10}
                  type="circle"
                  percent={14}
                  strokeColor="#44C658"
                  trailColor="#6B6565"
                />
                <GenderIcon width={17} height={28} fill="#707070" />
                <span className="gender-stats-info">Asian</span>
              </div>
            </div>

            <div className="gender-stats-status">
              <div className="gender-stats-iconlist">
                <i className="circle red"></i>
                &lt;20%
              </div>
              <div className="gender-stats-iconlist">
                <i className="circle yellow"></i>
                21% - 50%
              </div>
              <div className="gender-stats-iconlist">
                <i className="circle green"></i>
                &gt;50%
              </div>
            </div>
          </Card>
        </Col>
        <Col span={10}>
          <h4 className="patient-card-title">Locations</h4>
          <Card>
            <img src={Locations} />
          </Card>
        </Col>
      </Row> */}

      {/* patient reminder */}
      <div className="patient-recommender">
        <h2 className="insightdetail-title">Patient Recommender</h2>
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record.id),
            };
          }}
          dataSource={patientList}
          pagination={false}
          className="cmn-table"
          bordered
        >
          <Column title="Patient Name" dataIndex="name" key="name" />
          <Column title="ID" dataIndex="id" key="id" />
          <Column
            title="Exclusion/Inclusion Criteria"
            dataIndex="exclusioninclusioncriteria"
            key="exclusioninclusioncriteria"
            render={(tag: string) => <Tag className={getClass(tag)}>{tag}</Tag>}
          />
          <Column
            title="Demographic Fit"
            dataIndex="demographicfit"
            key="demographicfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Geographic Fit"
            dataIndex="geographicfit"
            key="geographicfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Condition Fit"
            dataIndex="conditionfit"
            key="conditionfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Procedure Fit"
            dataIndex="procedurefit"
            key="procedurefit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
          <Column
            title="Physical Activity Fit"
            dataIndex="physicalactivityfit"
            key="physicalactivityfit"
            render={(tag: string) => (
              <Tag className={getClass(tag)} key={tag}>
                {tag}
              </Tag>
            )}
          />
        </Table>
      </div>

      {/* Study Description */}
      <div className="study-desc">
        <h3 className="insightdetail-title">Study Description</h3>
        <p className="insightdetail-desc">
          <u>Brief Summary:</u>
          Adaptive reasoning and problem-solving are critical skills that allow for accurate
          decisions to be made based on varying levels of information. In many situations This
          protocol is a case-control, multicenter, diagnostic study to collect blood samples to
          support the development of blood-based screening tests for multiple cancers.
        </p>
        <Table
          columns={studydesccolumns}
          dataSource={studydescdata}
          pagination={false}
          className="cmn-table"
          bordered
        />
      </div>

      {/* Study Design */}
      <div className="study-design">
        <h3 className="insightdetail-title">Study Design</h3>
        <ul className="study-design-list">
          <li>
            Study Type :<span>Observational</span>
          </li>
          <li>
            Estimated Enrollment :<span>5400 participants</span>
          </li>
          <li>
            Observational Model :<span>Case-Control</span>
          </li>
          <li>
            Time Perspective :<span>Prospective</span>
          </li>
          <li>
            Official Title :
            <span>
              A Case Control Study for the Development of Multiomics Blood Tests for Cancer
              Screening
            </span>
          </li>
          <li>
            Actual Study Start Date :<span>February 15, 2022</span>
          </li>
          <li>
            Estimated Primary Completion Date :<span>May 31, 2024</span>
          </li>
          <li>
            Estimated Study Completion Date :<span>December 31, 2024</span>
          </li>
        </ul>
      </div>

      {/* Study Design */}
      <div className="study-desc">
        <h3 className="insightdetail-title">Groups and Cohorts</h3>
        <Table
          columns={groupcohortscolumns}
          dataSource={groupcohortsdata}
          pagination={false}
          className="cmn-table"
          bordered
        />
      </div>

      {/* Outcome measures */}
      <div className="outcome-measures">
        <h3 className="insightdetail-title">Outcome Measures</h3>
        <h5 className="insightdetail-subtitle">
          <u>Primary Outcome Measures :</u>
        </h5>
        <ol className="ordered-list">
          <li>
            Comparison of Blood Samples from Cancer Case and Non-cancer Control Participants [ Time
            Frame: 12 months ]
            <ul>
              <li>
                To compare blood samples from cancer case and non-cancer control subjects in order
                to develop and characterize blood-based multiomics tests in specific cancer types or
                in a combination of multiple cancers.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Biospecimen Retention: Samples With DNA <br />
          Blood specimens will be de-identified and used to validate the performance characteristics
          of the Freenome test
        </p>
      </div>

      {/* Eligibility Criteria */}
      <div className="outcome-measures">
        <h3 className="insightdetail-title">Eligibility Criteria</h3>
        <ul>
          <li>
            Ages Eligible for Study: <span>30 Years and older (Adult, Older Adult)</span>
          </li>
          <li>
            Sexes Eligible for Study: <span>All</span>
          </li>
          <li>
            Accepts Healthy Volunteers: <span>Yes</span>
          </li>
          <li>
            Sampling Method: <span>Non-Probability Sample</span>
          </li>
        </ul>
        <p>
          Study Population
          <br />
          Adults 30+ who have been newly diagnosed (treatment-naive) with cancer and Adults 30+
          without cancer
        </p>
        <p>Criteria</p>
        <p className="mb-0">Inclusion Criteria:</p>
        <ul>
          <li>Age at least 30 years</li>
          <li>Able and willing to provide blood samples per protocol</li>
          <li>Able to comprehend and willing to sign and date the informed consent documents</li>
          <li>
            Participants must meet one of the following:
            <ul>
              <li>Diagnosed with a single primary cancer that has not yet been treated</li>
              <li>
                No evidence or treatment of any cancer for at least 5 years prior to enrollment
              </li>
            </ul>
          </li>
        </ul>
        <p className="mb-0">Exclusion Criteria:</p>
        <ul>
          <li>
            A medical condition which, in the opinion of the investigator, should preclude
            enrollment in the study
          </li>
          <li>Known to be pregnant</li>
          <li>
            Any therapy for cancer, including surgery, chemotherapy, immunotherapy, and/or radiation
            therapy in the 5 years preceding enrollment
          </li>
          <li>
            Participated or currently participating in a clinical research study in which an
            experimental medication has been administered in the last 30 days
          </li>
          <li>Participated in or currently participating in another Freenome clinical study</li>
          <li>
            For the control cohort: Any previous cancer diagnosis in the 5 years preceding
            enrollment, or recurrence of the same primary cancer within any timeframe; or concurrent
            diagnosis of multiple primary cancers within any timeframe
          </li>
          <li>
            For the cancer cohorts: Any previous cancer diagnosis in the 5 years preceding
            enrollment, apart from the current cancer diagnosis; OR recurrence of the same primary
            cancer within any timeframe; OR concurrent diagnosis of multiple primary cancers within
            any timeframe
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InsightDetail;
