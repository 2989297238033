import React from 'react'

import Mark from './Mark'
import {selectionIsEmpty, selectionIsBackwards, splitWithOffsets} from './utils'
import {Span} from './span'

const Split = (props:any) => {
  if (props.mark) return <Mark {...props} />

  return (
    <span
      data-start={props.start}
      data-end={props.end}
      onClick={() => props.onClick({start: props.start, end: props.end})}
    >
      {props.content}
    </span>
  )
}

interface TextSpan extends Span {
  text: string
}


type TextBaseProps<T> = {
  content: string
  value: T[]
  onChange: (value: T[]) => any
  getSpan?: (span: TextSpan) => T
  // TODO: determine whether to overwrite or leave intersecting ranges.
}

type TextAnnotatorProps<T> = React.HTMLAttributes<HTMLDivElement> & TextBaseProps<T>

const TextAnnotator = <T extends Span>(props: TextAnnotatorProps<T>) => {
  const {content, value, style} = props
  const splits = splitWithOffsets(content, value)
  const getSpan = (span: TextSpan): T => {
    // TODO: Better typings here.
    if (props.getSpan) return props.getSpan(span) as T
    return {start: span.start, end: span.end, text:span.text} as T
  }
  

  const handleMouseUp = () => {
    if (!props.onChange) return

    const selection = window.getSelection()

    if(selection){
      if (selectionIsEmpty(selection)) return     

      let start =
        parseInt(String(selection?.anchorNode?.parentElement?.getAttribute('data-start')), 10) +
        selection.anchorOffset
      let end =
        parseInt(String(selection?.focusNode?.parentElement?.getAttribute('data-start')), 10) +
        selection.focusOffset

      if (selectionIsBackwards(selection)) {
        [start, end] = [end, start]
      }

      const findArr= [getSpan({start, end, text: content.slice(start, end)})];
      //props.onChange([...props.value, getSpan({start, end, text: content.slice(start, end)})])

      const locations = (word:string,substring:string)=>{
        const a=[];let i=-1;
        while((i=word.indexOf(substring,i+1)) >= 0){
          a.push(i);
        } 
        return a;
      }

      const selectedText =  content.slice(start, end);
      const foundOccurence = locations(content.toLowerCase(), selectedText.toLowerCase());
      const findData = foundOccurence.filter((x:any)=> x!==start);
      if(findData.length>0){
        for(const data of findData){
          start = data ;
          end = start + selectedText.length;       
          findArr.push(getSpan({start, end, text: content.slice(start, end)}))
        }  
      }

      props.onChange([...props.value, ...findArr]);
      window.getSelection()?.empty()
    }
  }

  const handleSplitClick = ({start, end}:{start:any,end:any}) => {
    // Find and remove the matching split.
    const splitIndex = props.value.findIndex(s => s.start === start && s.end === end)
    if (splitIndex >= 0) {
      let propValue:any[] = props.value;
      //props.onChange([...props.value.slice(0, splitIndex), ...props.value.slice(splitIndex + 1)])
      const text = propValue.find(s => s.start === start && s.end === end)?.text;
      const splitArr = propValue.filter(x=> x.text.toLowerCase() === text?.toLowerCase());
      for(const data of splitArr){
        const splitIdx = propValue.findIndex(s => s.start === data.start && s.end === data.end)
        propValue = [...propValue.slice(0, splitIdx), ...propValue.slice(splitIdx + 1)];
      }
      props.onChange([...propValue]);
    }
    
  } 

  
  return (
    <div style={style} onMouseUp={handleMouseUp}>
      {splits.map(split => (
        <Split key={`${split.start}-${split.end}`} {...split} onClick={handleSplitClick} />
      ))}
    </div>
  )
}

export default TextAnnotator
