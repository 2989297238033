import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  EVIDENCE_VIEWER_URL,
  NoResultsFoundMessage,
  PatientCardWrapper,
} from "../tile-helper-components";
import { PDL1Status } from "../../../../interfaces";

interface DataType {
  key: string;
  pdl1: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "% PDL-1 Expression",
    dataIndex: "pdl1",
    key: "pdl1",
  },
];

interface Props {
  pdl1Status: PDL1Status[];
  threshold: number;
  patientId: number;
}

export const PdStatusTile = ({ pdl1Status, patientId, threshold }: Props) => {
  const navigate = useNavigate();
  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title="PD-L1 Status">
      <Card className="cyan no-space patient-card">
        <Table
          columns={columns}
          locale={{
            emptyText: (
              <NoResultsFoundMessage aboveEightyLength={pdl1Status.length} belowEightyLength={0} />
            ),
          }}
          dataSource={pdl1Status.map((item, index) => ({
            pdl1: item.tps_value,
            key: `pdl1-status-item-${index}`,
          }))}
          pagination={false}
          className="pd-status-table"
          rowClassName={"cursor-pointer"}
          onRow={(record) => ({
            onClick: () => handleSearchNavigate(record.pdl1),
          })}
        />
      </Card>
    </PatientCardWrapper>
  );
};
