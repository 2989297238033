import { apiClient, GetSearchResultsRequest } from ".";

export interface Trait {
  Score: number;
  Name: string;
}

export interface BaseEntity {
  BeginOffset: number;
  EndOffset: number;
  Category: string;
  Id: number;
  Score: number;
  Text: string;
  Traits: Trait[];
  Type: string;
}

export interface Attribute extends BaseEntity {
  RelationshipScore: number;
  RelationshipType: string;
}

export interface Entities extends BaseEntity {
  Traits: Trait[];
  Attributes?: Attribute[];
}

export interface MedicalEntities {
  Entities: Entities[];
  ModelVersion: string;
  ResponseMetaData: any;
  UnmappedAttributes: any[];
}
export interface Form {
  key: string;
  value: string;
}
export interface NormalEntities {
  ResultList: [{ Entities: [] }, { Entities: Partial<Entities>[]; Index: number }];
}
export interface EvidencePageResponseInfo {
  content: string;
  heading: string;
  form: Form[];
  medicalEntities: MedicalEntities[];
  normalEntities: NormalEntities;
  pageNumber: number;
  patient: { patientId: number; name: string; fileName: string; mrn: string };
}
export interface SearchOccurrence {
  pageNumber: number;
  name: string;
  documentId: string;
  inOccured: number;
  content: string;
}
export interface EvidencePageSearchOccurrencesResponse {
  occurences: number;
  occurencesList: SearchOccurrence[];
}

export interface EvidenceFileData {
  fileName: string;
  status: boolean;
  url: string;
}

export interface EvidenceFileResponse {
  fileData: EvidenceFileData;
}

const getPageData = async (page: number, fileId: string) => {
  const res = await apiClient.get<EvidencePageResponseInfo>(`evidence/viewer/${page}/${fileId}`);
  return res;
};

const getPDFData =async () => {
  const res = await apiClient.get<EvidencePageResponseInfo>(
    `extracttextpdf`
  );
  return res;
}

const getOccurrencesPages = async (
  fileId: string,
  searchQuery: string | null,
  tileType: string | null
) => {
  let request: Partial<GetSearchResultsRequest> = {
    search: searchQuery ? searchQuery.split(",") : [],
  };
  if (tileType) request = { ...request, type: tileType };
  const res = await apiClient.post<EvidencePageSearchOccurrencesResponse>(
    `evidence/v2/occurance/${fileId}`,
    request
  );
  return res;
};

const submitFeedback = async (pageNo: number, fileUUID: string, comment: string) => {
  const res = await apiClient.post(`/evidence/feedback/${pageNo}/${fileUUID}`, { comment });
  return res;
};

const getFile = async (fileUUID: string) => {
  const res = await apiClient.get<EvidenceFileResponse>(`/evidence/getFile/${fileUUID}`);
  return res;
};

export const evidenceViewerService = {
  getPageData,
  getOccurrencesPages,
  submitFeedback,
  getFile,
  getPDFData
};
