import { searchItemsStorageEvent } from "./storage";
const removeItem = (name: string) => {
  return window.localStorage.removeItem(name);
};
const getItem = (name: string, format?: null | string) => {
  const item = window.localStorage.getItem(name);
  if (format === "json") {
    return JSON.parse(item || "{}");
  }
  return item;
};
const setItem = (name: string, value: any, format?: null | string) => {
  let v = value;
  if (format === "json") {
    v = JSON.stringify(v);
  }
  window.localStorage.setItem(name, v);
  window.dispatchEvent(searchItemsStorageEvent);
  return true;
};
const clearItems = () => {
  window.localStorage.clear();
  return true;
};

export const LocalStorage = {
  getItem,
  setItem,
  removeItem,
  clearItems,
};

export const filteredArray = (
  array: Array<Record<string, unknown>>,
  valueToRemove: unknown,
  filterByKey: string
) => array.filter((item) => item[filterByKey] !== valueToRemove);

export const getExistingSearchParams = (searchParams: URLSearchParams) => {
  const params = [];
  for (const entry of searchParams.entries()) {
    params.push(entry);
  }
  const res: { [key: string]: string } = {};
  params.map(([key, value]) => {
    res[key] = value;
  });
  return res;
};

export const hexToRGBColor = (hex: string, alpha = 1) => {
  const match = hex.match(/\w\w/g);
  if (match && match.length) {
    const [r, g, b] = match.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};

export const roundToNearestNumber = (value: number, roundToNumber: number) =>
  Math.ceil(value / roundToNumber) * roundToNumber;
