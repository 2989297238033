export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/password-create",
  CREATE_PASSWORD_SUCCESS: "/password-create-success",
  DASHBOARD: "/dashboard",
  ROLE_MAPPING: "/role-mapping",
  SEARCH: {
    LAYOUT: "/search",
    HOME: "",
    RESULT: "result",
  },
  PATIENTS_LIST: "/patients",
  PATIENT_PROFILE: "/patient",
  INSIGHT_DETAIL: "/insight",
  EVIDENCE_VIEWER: "/evidence",
  CLIENT_DETAIL: "/client/detail",
  SETTINGS: "/settings",
  PROJECT_DETAIL: "/project/detail",
  ANNOTATE_FILE: "/annotate-file",
  ANNOTATION: "/annotation",
  CONFIGURATIONS: "/configurations",
};

export const PAGE_TITLE = [
  { route: PAGE_URL.DASHBOARD, title: "Welcome to Autonomize" },
  { route: PAGE_URL.PATIENT_PROFILE, title: "Patient Insights" },
  { route: PAGE_URL.PATIENTS_LIST, title: "Patients" },
  { route: PAGE_URL.SEARCH.LAYOUT, title: "Search" },
  { route: PAGE_URL.SETTINGS, title: "Settings" },
  { route: PAGE_URL.PROJECT_DETAIL, title: "Project Detail" },
  { route: PAGE_URL.EVIDENCE_VIEWER, title: "Evidence Viewer" },
  { route: PAGE_URL.ROLE_MAPPING, title: "Roles Mapping" },
  { route: PAGE_URL.CONFIGURATIONS, title: "Configurations" },
  { route: PAGE_URL.ANNOTATION, title: "Upload File for Annotation" },
  { route: PAGE_URL.ANNOTATE_FILE, title: "Annotate File" },
];
