import React from "react";
import { TextAnnotator } from "../annotation";

// interface Props {
//   tag: string;
//   tagColor: string;
//   category: string;
//   page: number;
//   pageContent: string;
//   pageAnnotations: Annotation[];
//   setPageAnnotations: (value: Annotation[]) => void;
// }

export const AnnotateFileContent = (props:any) => {
  const { pageAnnotations, pageContent, setPageAnnotations, category, page, tag, tagColor } = props;
  return (
    <div className="e-content">
      <div className="e-paragraph annotate-text">
        <TextAnnotator
          content={pageContent}
          value={pageAnnotations}
          onChange={(e) => setPageAnnotations(e)}
          getSpan={(span) => ({
            ...span,
            tag: tag,
            color: tagColor,
            category: category,
            page: page
          })}
          // renderMark={(customProps) => (
          //   <mark
          //     key={customProps.key}
          //     onClick={() =>
          //       customProps.onClick({
          //         start: customProps.start,
          //         end: customProps.end,
          //         text: customProps.text,
          //         tag: customProps.tag,
          //         color: customProps.color,
          //       })
          //     }
          //     style={{
          //       padding: ".2em .3em",
          //       margin: "0 .25em",
          //       lineHeight: "1",
          //       display: "inline-block",
          //       borderRadius: ".25em",
          //       background: "#a6e22d",
          //     }}
          //   >
          //     {customProps.content}
          //     <span
          //       style={{
          //         padding: ".2em .3em",
          //         margin: "0 .25em",
          //         lineHeight: "1",
          //         display: "inline-block",
          //         borderRadius: ".25em",
          //         background: "#a6e22d",
          //       }}
          //     >
          //       {customProps.tag}
          //     </span>
          //   </mark>
          // )}
        />
      </div>
    </div>
  );
};
