import { Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  PatientDetailCardInfo,
  PatientCardWrapper,
  NoResultsFoundMessage,
} from "../tile-helper-components";
import { PembrolizumabTherapy } from "../../../../interfaces";

interface Props {
  pembrolizumabTherapy: PembrolizumabTherapy[];
  threshold: number;
  patientId: number;
}

export const PembrolizumabTherapyTile = ({ pembrolizumabTherapy, patientId, threshold }: Props) => (
  <PatientCardWrapper title="Pembrolizumab Therapy">
    <Card>
      <NoResultsFoundMessage
        aboveEightyLength={pembrolizumabTherapy.length}
        belowEightyLength={0}
      />
      {pembrolizumabTherapy.map((item, index) => (
        <Link
          key={`pembrolizumab-therapy-item-link-${index}`}
          to={`${EVIDENCE_VIEWER_URL}&search=${item}&patient_id=${patientId}`}
        >
          <PatientDetailCardInfo
            key={`pembrolizumab-therapy-item-${index}`}
            patientDetailAttribute={{
              key: item,
              value: "",
            }}
            title={CONFIDENCE_SCORE_INFO}
          />
        </Link>
      ))}
    </Card>
  </PatientCardWrapper>
);
