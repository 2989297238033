import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AnnotateFileContent,
  AnnotateFileHeader,
  CATEGORY,
  EXPORT_OPTIONS,
  downloadFile,
  exportToCsv,
  groupByContent,
  exportToIOBTSV,
} from ".";
import { annotationService } from "../../../api";
import { Loading, PdfViewer } from "../../../components";
import {
  AnnotationFilePageContent,
  Annotation,
  SaveAnnotationsRequest,
  AnnotationProjectFile,
} from "../../../interfaces";
import { clearAnnotations, RootState, updateAnnotations } from "../../../store";
import { ANNOTATION_FILE_STATUS } from "../../../utils";
//import { iobFormatter } from "../../../utils/annotateUtils";
import "./annotation.scss";

interface Props {
  fileId: string;
}

export const AnnotateFileContainer = ({ fileId }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fetchPageAnnotations, setFetchPageAnnotations] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [category, setCategory] = useState(CATEGORY[0].id);
  const [tag, setTag] = useState(CATEGORY[0].options[0].id);
  const [tagColor, setTagColor] = useState(CATEGORY[0].color);
  //ocr amazon textract
  //const [pageContents, setPageContents] = useState([] as string[]);

  const [pageContents, setPageContents] = useState([[] as AnnotationFilePageContent[]]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [currPageContent, setCurrPageContent] = useState("");
  // stores annotations across all pages
  const { annotations } = useSelector((state: RootState) => state.annotation);
  // stores currrent page annotations
  const [currPageAnnotations, setCurrPageAnnotations] = useState([] as Annotation[]);
  const [fileData, setFileData] = useState({} as AnnotationProjectFile);
  const onPageUpdate = (page: number) => {
    dispatch(updateAnnotations({ pageAnnotations: currPageAnnotations, page: currPage }));
    setCurrPage(page);
  };

  const onAnnotationsExport = async (exportAs: string) => {
    const combinedAnnotations = [...currPageAnnotations] as Annotation[];
    // console.log("pageContent", pageContents)
    Object.keys(annotations)
      .map((page) => Number(page))
      .map((page) => {
        const annotationsInPage: Annotation[] = Number(page) === currPage ? [] : annotations[page];
        if (annotationsInPage.length > 0) combinedAnnotations.push(...annotationsInPage);
      });

    switch (exportAs) {
      // JSON
      case EXPORT_OPTIONS[0].id:
        downloadFile({
          data: JSON.stringify(groupByContent(combinedAnnotations, pageContents)),
          fileName: "result.json",
          fileType: "text/json",
        });
        break;

      // CSV
      case EXPORT_OPTIONS[1].id:
        exportToCsv(combinedAnnotations, ",");
        break;

      // TSV
      case EXPORT_OPTIONS[2].id:
        exportToCsv(combinedAnnotations, "\t");
        break;

      //IOB
      case EXPORT_OPTIONS[3].id: {
        const response = await annotationService.exportIOB(
          groupByContent(combinedAnnotations, pageContents)
        );
        exportToIOBTSV(response, "\t");
        break;
      }
    }
  };

  const onAnnotationsSave = async () => {
    const reqObj = [] as SaveAnnotationsRequest[];
    Object.entries(annotations).forEach(([page, pageAnnotations]) => {
      // add annotations other than current page
      if (pageAnnotations.length > 0 && currPage !== Number(page))
        reqObj.push({
          AnnotatedJson: JSON.stringify(pageAnnotations),
          FileId: Number(fileId),
          Page: Number(page),
        });
    });
    // add annotations for current page
    if (currPageAnnotations.length > 0)
      reqObj.push({
        AnnotatedJson: JSON.stringify(currPageAnnotations),
        FileId: Number(fileId),
        Page: currPage,
      });
    if (reqObj.length > 0) {
      await annotationService.saveAnnotatedData(reqObj);
    }
  };

  const onAnnotationComplete = async () => {
    const reqObj = {} as AnnotationProjectFile;
    reqObj.FileStatus = ANNOTATION_FILE_STATUS.Completed;
    reqObj.Id = Number(fileId);
    const response = await annotationService.updateFileStatus(reqObj);
    return response;
  };

  useEffect(() => {
    const fetchFile = async () => {
      setLoading(true);
      const { data } = await annotationService.getFile(fileId);
      const { data: fileDataRes } = await annotationService.getFileById(fileId);
      setFileData(fileDataRes.data);
      setPdfUrl(data.data.pdfURL);
      setPageContents(data.data.data.map((item) => item.content));
      setLoading(false);
      setFetchPageAnnotations(true);
    };
    fetchFile();
    return () => {
      dispatch(clearAnnotations());
    };
  }, [dispatch, fileId]);

  // page content
  useEffect(() => {
    let pageContent = "";
    if (pageContents.length >= currPage) {
      //comment for amazon ocr textract
      // pageContent = pageContents[currPage - 1];
      // setCurrPageContent(pageContent);

      //code for pdfextract
      const content = pageContents[currPage - 1];
      for (const res of content) {
        pageContent += res.str + " ";
      }
      setCurrPageContent(pageContent);
    }
  }, [currPage, pageContents]);

  // page annotations
  useEffect(() => {
    const fetchPage = async () => {
      setPageLoading(true);
      const { data } = await annotationService.getAnnotatedData(fileId, currPage);
      const stringyfiedAnnotationsInPage = [] as string[];
      // add annotations from API
      if (data.response) {
        const parsedPageAnnotations: Annotation[] = JSON.parse(data.response.AnnotatedJson);
        stringyfiedAnnotationsInPage.push(
          ...parsedPageAnnotations.map((item) => JSON.stringify(item))
        );
      }
      // add annotations from store
      if (currPage in annotations)
        stringyfiedAnnotationsInPage.push(
          ...annotations[currPage].map((item) => JSON.stringify(item))
        );

      // filter common annotations from API and store
      const annotationsInPage: Annotation[] = Array.from(new Set(stringyfiedAnnotationsInPage)).map(
        (item) => JSON.parse(item)
      );
      setCurrPageAnnotations(annotationsInPage);
      setPageLoading(false);
    };
    if (fetchPageAnnotations) fetchPage();
  }, [annotations, currPage, dispatch, fetchPageAnnotations, fileId]);

  return (
    <>
      <AnnotateFileHeader
        {...{
          category,
          setCategory,
          setTag,
          setTagColor,
          tag,
          fileData,
          onAnnotationsSave,
          onAnnotationsExport,
          onAnnotationComplete,
        }}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="evidence-page m-t">
          {pageLoading ? (
            <Loading />
          ) : (
            <AnnotateFileContent
              {...{
                tag,
                tagColor,
                category,
                page: currPage,
                pageContent: currPageContent,
                pageAnnotations: currPageAnnotations,
                setPageAnnotations: setCurrPageAnnotations,
              }}
            />
          )}
          <PdfViewer
            pdf={pdfUrl}
            pageInfo={{ height: 700, width: 400 }}
            displayPagination
            setCurrPage={onPageUpdate}
            currPage={currPage}
          />
        </div>
      )}
    </>
  );
};
