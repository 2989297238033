import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { IPatient, ProjectSpecificTiles } from "../../interfaces";
import { Tabs } from "antd";
import {
  PatientInsightActionItems,
  PatientInsightTiles,
  ProjectSpecificInsightTiles,
} from "./patient-insight-container";
import { PatientDetailCardProps, PATIENT_INSIGHT_TABS } from ".";
import { PatientProjects } from "../../api";
import { isEmptyObject } from "../../utils";
import "./patient-insight-container.scss";

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
}

export const PatientInsightContainer = (props: Props) => {
  const { patientInfo } = props;
  const [component, setComponent] = useState(<></>);
  const [selectedTab, setSelectedTab] = useState(PATIENT_INSIGHT_TABS.PATIENT_INSIGHTS);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const finalTabs = useMemo(() => {
    const projectSpecific = patientInfo.project_specific;
    let tabs = [PATIENT_INSIGHT_TABS.PATIENT_INSIGHTS];
    if (!isEmptyObject(projectSpecific)) {
      setSelectedTab(PATIENT_INSIGHT_TABS.PROJECT_SPECIFIC_INSIGHTS);
      tabs = Object.values(PATIENT_INSIGHT_TABS);
    }
    return tabs;
  }, [patientInfo]);

  useEffect(() => {
    let res = <></>;
    const projectSpecific: ProjectSpecificTiles = patientInfo.project_specific
      ? patientInfo.project_specific
      : ({} as ProjectSpecificTiles);

    switch (selectedTab) {
      case PATIENT_INSIGHT_TABS.PATIENT_INSIGHTS:
        res = <PatientInsightTiles patientInfo={patientInfo} />;
        break;
      case PATIENT_INSIGHT_TABS.PROJECT_SPECIFIC_INSIGHTS:
        res = (
          <ProjectSpecificInsightTiles
            projectSpecific={projectSpecific}
            patientId={patientInfo.patientId}
            threshold_confidence_score={patientInfo.threshold_confidence_score}
            biomarkers={patientInfo.biomarkers}
            riskFactors={patientInfo.risk_factor}
          />
        );
        break;
    }
    setComponent(res);
  }, [patientInfo, selectedTab]);

  return (
    <>
      <div className="flex jcsb bd-bt">
        <div className="tabs no-border">
          <Tabs onChange={onChange} activeKey={selectedTab}>
            {finalTabs.map((key) => (
              <Tabs.TabPane tab={key} key={key} />
            ))}
          </Tabs>
        </div>
      </div>
      <>
        <PatientInsightActionItems {...props} />
        <div className="tabs-content">{component}</div>
      </>
    </>
  );
};
