import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import { logoutUser, setPatientList, openModal } from "../../../store";
import { DATA_ADMIN_PERMISSIONS, MODAL_TYPE, PAGE_TITLE, USER_ROLES_CODE } from "../../../utils";
import { PAGE_URL } from "../../../utils";
import { EditProfileIcon, LogoutIcon, MenuIcon, UserSwitchIcon } from "../../../assets/icons";
import { useUser } from "../../../hooks";
import { PatientProfileService } from "../../../api";
import "./app-header.scss";

export const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    firstName,
    lastName,
    originalRoleName,
    isImpersonationActive,
    roleName,
    originalRoleCode,
    roleCode,
  } = useUser();
  const displaySearch =
    roleCode === USER_ROLES_CODE.CLINICIAN || DATA_ADMIN_PERMISSIONS.includes(roleCode);
  const pageTitle = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) title = page.title;
    });
    return title;
  }, [location.pathname]);

  useEffect(() => {
    const fetchPatientsList = async () => {
      if (displaySearch) {
        const { data } = await PatientProfileService.getPatientsForPagination();
        dispatch(setPatientList(data.data));
      }
    };
    fetchPatientsList();
  }, [dispatch, displaySearch]);

  const logout = (e: any) => {
    e.preventDefault();
    dispatch(logoutUser());
    navigate(PAGE_URL.LOGIN);
  };

  return (
    <Header className="flex aic jcsb header">
      <div>
        <h1 className="header-h1 m-0">{pageTitle}</h1>
        {isImpersonationActive && (
          <div className="impersonation-bar">{`Impersonation for ${roleName}`}</div>
        )}
      </div>
      {/* <Button onClick={() => toggleTheme()}>toggle theme</Button> */}
      <div className="header-right flex gp-10 aic">
        <div className="header-content-menu pr cursor-pointer">
          <a className="header-dropdown" href="#">
            <MenuIcon />
          </a>
          <div className="header-dropdown-menu flex flex-column">
            <div className="header-content-profile-content">
              <p>
                {firstName} {lastName} <span>{originalRoleName}</span>
              </p>
            </div>
            <a
              href="#"
              className="header-dropdown-menu-item flex aic no-wrap"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openModal(MODAL_TYPE.EDIT_PROFILE));
              }}
            >
              <EditProfileIcon />
              Edit Profile
            </a>
            {originalRoleCode === USER_ROLES_CODE.ENTERPRISE_ADMIN && (
              <a
                href="#"
                className="header-dropdown-menu-item flex aic no-wrap"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(openModal(MODAL_TYPE.USER_MULTIPLE_ROLES));
                }}
              >
                <UserSwitchIcon />
                Switch Role
              </a>
            )}
            <a href="#" className="header-dropdown-menu-item flex aic no-wrap" onClick={logout}>
              <LogoutIcon />
              Logout
            </a>
          </div>
        </div>
      </div>
    </Header>
  );
};
