import { useCallback, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { PatientProfileService, PatientProjects } from "../api";

export const useFetchPatientProjects = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const projectId = searchParams.get("projectId");
  const [selectedProject, setSelectedProject] = useState({} as PatientProjects);
  const [projects, setProjects] = useState([] as PatientProjects[]);
  const [loading, setLoading] = useState(false);

  const handleProjectSelect = useCallback(
    (item: PatientProjects) => {
      navigate(
        { search: createSearchParams({ projectId: item.Id.toString() }).toString() },
        { replace: true }
      );
    },
    [navigate]
  );

  const fetchPatientProjects = useCallback(
    async (patientId: string) => {
      setLoading(true);
      const { data } = await PatientProfileService.getPatientProjects(patientId);
      setProjects([...data.data]);
      if (data.data.length > 0) {
        if (projectId) {
          const projectIndex = data.data.findIndex((item) => item.Id === Number(projectId));
          if (projectIndex !== -1) setSelectedProject(data.data[projectIndex]);
          else handleProjectSelect(data.data[0]);
        } else handleProjectSelect(data.data[0]);
      }
      setLoading(false);
    },
    [handleProjectSelect, projectId]
  );

  return {
    fetchPatientProjects,
    patientProjects: projects,
    selectedProject,
    patientProjectsLoading: loading,
    setSelectedProject: handleProjectSelect,
  };
};
