import React, { useState } from "react";
import { Col, Tabs, Badge } from "antd";
import { ProjectList } from "./ProjectList";
import "./dashboard-tabs.scss";

const { TabPane } = Tabs;
const onChange = (key: string) => {
  // console.log(key);
};
export const DashboardTabs = () => {
  const [show] = useState(true);
  return (
    <Col span={24}>
      <Tabs className="dashboard-tabs" defaultActiveKey="1" onChange={onChange}>
        <TabPane
          tab={
            <>
              All Study
              <Badge className="tab-badge green" count={show ? "07" : 0} />
            </>
          }
          key="1"
        >
          <ProjectList />
        </TabPane>
        <TabPane
          tab={
            <>
              In Progress
              <Badge className="tab-badge orange" count={show ? "04" : 0} />
            </>
          }
          key="2"
        >
          <ProjectList />
        </TabPane>
        <TabPane
          tab={
            <>
              Pending
              <Badge className="tab-badge red" count={show ? "03" : 0} />
            </>
          }
          key="3"
        >
          <ProjectList />
        </TabPane>
        <TabPane
          tab={
            <>
              Completed
              <Badge className="tab-badge blue" count={show ? "67" : 0} />
            </>
          }
          key="4"
        >
          <ProjectList />
        </TabPane>
      </Tabs>
    </Col>
  );
};
