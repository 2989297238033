import { combineReducers } from "redux";

import uiReducer, { uiInitialState } from "./ui/slice";
import searchReducer, { searchInitialState } from "./search/slice";
import userReducer, { userInitialState } from "./user/slice";
import dataReducer, { dataInitialState } from "./data/slice";
import patientReducer, { patientSliceInitialState } from "./patient-profile/slice";
import projectReducer, { projectInitialState } from "./project/slice";
import annotationReducer, { annotationInitialState } from "./annotation-tool/slice";

export const initialState = {
  ui: uiInitialState,
  search: searchInitialState,
  user: userInitialState,
  data: dataInitialState,
  patient: patientSliceInitialState,
  project: projectInitialState,
  annotation: annotationInitialState,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    search: searchReducer,
    user: userReducer,
    data: dataReducer,
    patient: patientReducer,
    project: projectReducer,
    annotation: annotationReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
