import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";
import { EVIDENCE_VIEWER_URL } from "./tile-helper-components";
import { IPatient, PatientStatusDropdownOption } from "../../../interfaces";
import { PatientProjects, projectService } from "../../../api";
import { useFetchPatientStatusOptions, useUser } from "../../../hooks";
import { Loading, PatientStatusDropdown } from "../../../components";
import { DATA_ADMIN_PERMISSIONS, getLocalDateTimeStringFromISO, MODAL_TYPE } from "../../../utils";
import { openModal, updateAssignPatientsList } from "../../../store";
import { PatientDetailCardProps } from "..";

const { Search } = Input;

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
}

export const PatientInsightActionItems = (props: Props) => {
  const { patientInfo, patientProjects, selectedProject, setReviewStatusMap } = props;
  const { projectStatusInfo: patientStatusInfo, patientId } = patientInfo;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roleCode } = useUser();
  const projectId = selectedProject.Id ? selectedProject.Id : -1;

  const {
    fetchPatientStatusList,
    setPatientStatusLoading,
    patientStatusLoading,
    patientStatusOptions,
  } = useFetchPatientStatusOptions();

  const handleSearchSubmit = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientInfo.patientId}`);
  };

  const handleStatusChange = async (value: string, option: PatientStatusDropdownOption) => {
    setPatientStatusLoading(true);
    const { data } = await projectService.updatePatientStatus(
      option.id,
      patientStatusInfo?.patientProjectId
    );
    setReviewStatusMap([
      { heading: "Reviewed By", value: data.data.updatedBy },
      {
        heading: "Reviewed On",
        value: getLocalDateTimeStringFromISO(data.data.updatedAt),
      },
    ]);
    setPatientStatusLoading(false);
  };

  const onAssignProject = () => {
    dispatch(
      updateAssignPatientsList({
        patientIds: [patientId],
        alreadyAssignedProjects: patientProjects.map((item) => item.Id),
      })
    );
    dispatch(openModal(MODAL_TYPE.ADD_PATIENTS_TO_PROJECT));
  };

  useEffect(() => {
    const getPatientStatusOptions = async () => {
      if (projectId !== -1) {
        await fetchPatientStatusList();
      }
    };
    getPatientStatusOptions();
  }, [fetchPatientStatusList, projectId]);

  return (
    <div className="flex gp aic">
      <div className="custom-search">
        <Search
          className="record-search"
          placeholder="Search within Records"
          style={{ width: "278px" }}
          onSearch={handleSearchSubmit}
        />
      </div>
      {projectId !== -1 && (
        <div className="ai-select-box" style={{ flex: "auto", maxWidth: 210 }}>
          <PatientStatusDropdown
            value={patientStatusInfo?.projectStatusName}
            options={patientStatusOptions}
            loading={patientStatusLoading}
            onSelect={handleStatusChange}
            notFoundContent={patientStatusLoading && <Loading />}
          />
        </div>
      )}
      {DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
        <Button
          type="primary"
          style={{ marginLeft: "auto" }}
          className="outline"
          onClick={onAssignProject}
        >
          Assign to Project
        </Button>
      )}
    </div>
  );
};
