import React from "react";
import { Card, Progress } from "antd";
import { FemaleIcon, GenderIcon, OldPeopleIcon, UnderScoreIcon } from "../../../assets/icons";
import "./diversity-of-cohort.scss";

export const DiversityOfCohort = () => {
  return (
    <div>
      <h3 className="basic-detail-title">Diversity of Cohort</h3>
      <Card className="basic-detail-content p-10">
        <div className="gender-stats-wrapper">
          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#FF0018"
              trailColor="#6B6565"
            />
            <FemaleIcon width={17} height={25} fill="" />
            <span className="gender-stats-info">Female</span>
          </div>

          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#E8AE55"
              trailColor="#6B6565"
            />
            <GenderIcon width={17} height={25} fill="" />
            <span className="gender-stats-info">Hispanic</span>
          </div>

          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#E8AE55"
              trailColor="#6B6565"
            />
            <GenderIcon width={17} height={25} fill="" />
            <span className="gender-stats-info">White</span>
          </div>

          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#FF0018"
              trailColor="#6B6565"
            />
            <OldPeopleIcon width={17} height={25} fill="" />
            <span className="gender-stats-info align">
              <UnderScoreIcon className="underScore" /> 65 years
            </span>
          </div>

          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#E8AE55"
              trailColor="#6B6565"
            />
            <GenderIcon width={17} height={25} fill="" />
            <span className="gender-stats-info">Black</span>
          </div>

          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#44C658"
              trailColor="#6B6565"
            />
            <GenderIcon width={17} height={25} fill="" />
            <span className="gender-stats-info">United States</span>
          </div>

          <div className="gender-stats">
            <Progress
              width={45}
              strokeWidth={10}
              type="circle"
              percent={0}
              strokeColor="#44C658"
              trailColor="#6B6565"
            />
            <GenderIcon width={17} height={25} fill="" />
            <span className="gender-stats-info">Asian</span>
          </div>
        </div>

        <div className="gender-stats-status">
          <div className="gender-stats-iconlist">
            <i className="circle red"></i>
            &lt;20%
          </div>
          <div className="gender-stats-iconlist">
            <i className="circle yellow"></i>
            21% - 50%
          </div>
          <div className="gender-stats-iconlist">
            <i className="circle green"></i>
            &gt;50%
          </div>
        </div>
      </Card>
    </div>
  );
};
