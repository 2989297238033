import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { usersService } from "../../api";
import { getUserColumns } from "../../components";
import { RootState, deleteUser, updateDeleteConfirmation, openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { MailServiceConfiguration } from "../configuration";

interface Props {
  clientId: number;
}

export const ClientDetailContainer = ({ clientId }: Props) => {
  const dispatch = useDispatch();
  const columns = getUserColumns(dispatch);
  const { id: userId, list } = useSelector((state: RootState) => state.data.users).users;
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [tableLoading, setTableLoading] = useState(false);

  const usersData: UserColumnDataType[] = list.map((item) => {
    const { email, id, firstName, lastName, status, roleName } = item;
    return {
      key: id,
      name: firstName + " " + lastName,
      email,
      action: "",
      status,
      role: roleName,
    };
  });

  useEffect(() => {
    const deleteUserAPI = async () => {
      setTableLoading(true);
      await usersService.deleteOne(userId);
      dispatch(deleteUser({ id: userId }));
      dispatch(updateDeleteConfirmation(false));
      setTableLoading(false);
    };

    if (isDeleteConfirmed && userId !== -1) deleteUserAPI();
  }, [userId, dispatch, isDeleteConfirmed]);

  return (
    <>
      <MailServiceConfiguration clientId={clientId} />
      <div className="clientList m-t">
        <div className="flex jcsb aic clientDetails-title">
          <p className="table-heading">Enterprise Admins</p>
          <Button
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
            }}
          >
            Add Enterprise Admin
          </Button>
        </div>
        <div className="client-table user-table">
          <Table
            dataSource={usersData}
            columns={columns}
            loading={tableLoading}
            pagination={false}
          ></Table>
        </div>
      </div>
    </>
  );
};
