import React from "react";
import { IconsProps } from "./Icons";

export const OldPeopleIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 25 28"}
      width={width ? width : "25"}
      height={height ? height : "28"}
      fill={fill ? fill : "currentColor"}
    >
      <g clipPath="url(#clip0_529_675)">
        <path
          d="M24.6144 26.5836L18.1291 17.1445C17.9631 17.3922 17.7616 17.6177 17.5143 17.8008C17.2702 17.9822 17.0001 18.1119 16.7164 18.2004L23.152 27.5684C23.233 27.6863 23.342 27.783 23.4694 27.8498C23.5968 27.9166 23.7387 27.9515 23.8829 27.9516C24.0557 27.9516 24.2283 27.9025 24.3804 27.8C24.7858 27.5734 24.8908 27.032 24.6144 26.5836ZM7.91175 5.20078C9.37745 5.20078 10.5662 4.02555 10.5662 2.625C10.5662 1.22445 9.42666 0 7.96151 0C6.49637 0 5.30767 1.17523 5.30767 2.625C5.30767 4.07477 6.49637 5.20078 7.91175 5.20078ZM5.30822 19.0367L3.59262 25.8289C3.35421 26.7673 3.93126 27.7173 4.87973 27.9513C5.02348 27.9836 5.16834 28 5.30988 28C6.10271 28 6.82367 27.4694 7.02382 26.6749L8.24238 21.8526L5.30822 18.9492V19.0367ZM17.3384 14.7L13.0934 9.1C12.0971 7.78203 10.5103 7 8.84613 7L7.49709 6.95078C5.47408 6.95078 3.65454 8.06258 2.74947 9.8525L0.187235 14.968C-0.24982 15.832 0.104302 16.882 0.978579 17.3141C1.22362 17.4356 1.49398 17.4988 1.7681 17.4986C2.41774 17.4986 3.04139 17.1449 3.35266 16.5312L5.30877 12.6615V15.7486C5.30877 16.2126 5.49537 16.6575 5.8271 16.9862L10.6167 21.7238V26.2492C10.6167 27.2155 11.4079 27.9992 12.386 27.9992C13.364 27.9992 14.1552 27.2155 14.1552 26.2492V21.7238C14.1552 20.7892 13.7872 19.9104 13.1185 19.2492L10.6154 16.7781V11.6648L14.5066 16.7989C15.0921 17.5705 16.1995 17.7275 16.9824 17.1492C17.7642 16.5703 17.9245 15.4711 17.3384 14.7Z"
          fill={fill ? fill : "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_529_675">
          <rect width="24.7692" height="28" fill={fill ? fill : "currentColor"} />
        </clipPath>
      </defs>
    </svg>
  );
};
