import React from "react";
import { Card } from "antd";
import { Demographics } from "../../../../interfaces";
import { PatientCardWrapper, PatientDetailCardInfo } from "../tile-helper-components";
import "../patientprofile.scss";

interface Props {
  demographics: Demographics;
}

export const DemographicsCard = ({ demographics }: Props) => {
  const {
    year_of_birth,
    sex,
    race,
    blood_type,
    ethnicity,
    income,
    marital_status,
    occupation,
    zip_code,
  } = demographics;

  const mapper: { [key: string]: string } = {
    "Year of birth": year_of_birth,
    "Biological Sex": sex,
    Race: race,
    Ethnicity: ethnicity,
    "Zip Code": zip_code,
    Occupation: occupation,
    "Income Level": income,
    "Marital Status": marital_status,
    "Blood Type": blood_type,
  };

  return (
    <PatientCardWrapper title={"Demographics"}>
      <Card className="orange patient-card">
        {Object.keys(mapper).map((item, index) => (
          <PatientDetailCardInfo
            key={`demographics-item-${index}`}
            patientDetailAttribute={{
              key: item,
              value: mapper[item],
            }}
          />
        ))}
      </Card>
    </PatientCardWrapper>
  );
};
