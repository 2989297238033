import { Row, Col } from "antd";
import React from "react";
import { ProjectStatus } from "../../../components";
import { DiversityOfCohort, Locations } from "../project-detail-components";
//import "./insights.scss";

interface Props {
  projectId: number;
}

export const ProjectDetailInsights = ({ projectId }: Props) => {
  return (
    <>
      <h4 className="demo-text basic-detail-subtitle">
        Demo purposes only, please contact your Autonomize representative to activate/learn more.
      </h4>
      <div className="basic-detail-content content-gap p-20 gp-top-20">
        <Row gutter={20} className="study-desc" equal-heights>
          <Col span={12}>
            <ProjectStatus {...{ projectId, showDropdown: false }} />
          </Col>
          <Col span={12}>
            <DiversityOfCohort />
          </Col>
          <Col span={24}>
            <Locations />
          </Col>
        </Row>
      </div>
    </>
  );
};
