import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProjectColumns } from "../../components";
import {
  deleteProject,
  openModal,
  RootState,
  setProjects,
  updateDeleteConfirmation,
} from "../../store";
import { DATA_ADMIN_PERMISSIONS, MODAL_TYPE } from "../../utils";
import { projectService } from "../../api";
import { useUser } from "../../hooks";
import "./project-list.scss";

export const ProjectsList = () => {
  const dispatch = useDispatch();
  const { roleCode, clientId } = useUser();
  const projectColumns = getProjectColumns(dispatch, roleCode);
  const { projects } = useSelector((state: RootState) => state.data);

  const { id: projectId } = useSelector((state: RootState) => state.data.projects);
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [loading, setLoading] = useState(false);
  const projectData: ProjectColumnDataType[] = projects.list.map((item) => {
    const { Description, Id, Name, OrganizationId, status } = item;
    return {
      key: Id,
      name: Name,
      description: Description,
      details: "",
      clientId: OrganizationId,
      status,
    };
  });

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data } = await projectService.getAll(clientId);
      const { data: projectList } = data;
      dispatch(setProjects(projectList));
      setLoading(false);
    };
    fetch();
  }, [clientId, dispatch]);

  useEffect(() => {
    const deleteProjectAPI = async () => {
      await projectService.deleteOne(clientId, projectId);
      dispatch(deleteProject(projectId));
      dispatch(updateDeleteConfirmation(false));
    };

    if (isDeleteConfirmed && projectId !== -1) deleteProjectAPI();
  }, [projectId, dispatch, isDeleteConfirmed, clientId]);

  return (
    <>
      <div className="flex aie jcsb m-t">
        <h3 className="no-padding">Projects</h3>
        {DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
          <Button
            // type="primary"
            // icon={<PlusOutlined />}
            className="outline"
            onClick={() => {
              dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
            }}
          >
            Add New Project
          </Button>
        )}
      </div>
      <div className="client-table project-list basic-detail-content">
        <Table
          dataSource={projectData}
          columns={projectColumns}
          loading={loading}
          pagination={false}
        ></Table>
      </div>
    </>
  );
};
