import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./back-button.scss";

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className="back-icon" onClick={() => navigate(-1)}>
      <a>
        <LeftOutlined />
        Back
      </a>
    </div>
  );
};
