import React from "react";
import { AWSServiceConfiguration } from "../containers/configuration";
import { PageWrapper, EnterpsieAdminConfigurationsContainer } from "../containers";
import { useUser } from "../hooks";

const EnterpsieAdminConfigurations = () => {
  return <PageWrapper pageContainerChildren={<EnterpsieAdminConfigurationsContainer />} />;
};

export default EnterpsieAdminConfigurations;
