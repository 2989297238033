import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { autonomizeFullLogoBlack } from "../assets/images";
import { useUser } from "../hooks";
import { PAGE_URL } from "../utils";

export const GuestLayout = () => {
  const navigate = useNavigate();
  const { accessToken } = useUser();

  useEffect(() => {
    if (accessToken && accessToken.length > 0) navigate(PAGE_URL.DASHBOARD);
  }, [accessToken, navigate]);

  return (
    <div className="user-page flex aic jcc">
      <div className="user-page-container flex flex-column">
        <div className="brand-logo">
          <img
            className="brand-logo-img"
            src={autonomizeFullLogoBlack}
            alt="Autonomize Logo"
            // width={202}
            // height={40}
          />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
