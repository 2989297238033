import { AxiosResponse } from "axios";
import { apiClient } from ".";
import { IPagination, IPatient } from "../interfaces";

export interface PatientListInfo {
  id: number;
  name: string;
  patientId: string;
  age: number;
  gender: string;
  source: string;
  exclusioninclusioncriteria: string;
  demographicfit: string;
  geographicfit: string;
  conditionfit: string;
  procedurefit: string;
  physicalactivityfit: string;
  PatientRelationId: number;
}

export interface PatientWithBasicInfo {
  Id: number;
  Name: string;
  Dob: string;
  Gender: string;
  IsDeleted: boolean;
  OrganizationId: number;
  CreatedBy: number;
  Mrn: string;
  createdAt: string;
  updatedAt: string;
}

export interface PatientPaginationMapping {
  patientId: number;
  sequenceNo: number;
}

interface PatientPaginationMappingResponse {
  data: PatientPaginationMapping[];
  message: string;
  total: number;
}

interface PatientListWithBasicInfoResponse {
  data: PatientWithBasicInfo[];
  message: string;
}

interface PatientListResponse {
  list: PatientListInfo[];
}

interface PatientListByProjectIdResponse {
  list: {
    projectData: {
      name: string;
      s3Folder: string;
    };
    projectDocument: PatientListInfo[];
  };
}

interface PatientListService {
  (): Promise<AxiosResponse<PatientListResponse>>;
}

export interface AddPatientsToProjectRequest {
  projectId: number;
  patientIds: number[];
}

export interface PatientProjects {
  Id: number;
  Name: string;
  S3Folder: string;
  createdAt: string;
}

interface PatientProjectsResponse {
  message: string;
  data: PatientProjects[];
}

export interface PatientWithFilters extends PatientWithBasicInfo {
  projectId: number;
  projectName: string;
  projectStatusColorCode: string;
  projectStatusUpdatedAt: string;
  projectStatusId: number;
  projectStatusName: string;
  CreatedAt: string;
}

interface PatientswithFiltersResponse {
  message: string;
  data: PatientWithFilters[];
  pagination: IPagination;
}

const getPatientInfo = async (patientId: string, projectId: string | null) => {
  const res = await apiClient.get<IPatient>(
    `profile/v2/${patientId}${projectId ? `?projectId=${projectId}` : ""}`
  );
  return res;
};

const getPatientListWithBasicInfo = async () => {
  const res = await apiClient.get<PatientListWithBasicInfoResponse>(`/patient/list`);
  return res;
};

const getPatientsListByProjectId = async (projectId: number) => {
  const res = await apiClient.get<PatientListByProjectIdResponse>(`profile/list/${projectId}`);
  return res;
};

const getPatientsList: PatientListService = async () => {
  const res = await apiClient.get(`profile/list`);
  return res;
};

const addPatientsToProject = async (clientId: number, reqObj: AddPatientsToProjectRequest) => {
  const res = await apiClient.post<PatientListByProjectIdResponse>(
    `/patient/project/${clientId}`,
    reqObj
  );
  return res;
};

const getPatientsListWithFilters = async (queryString?: string) => {
  const res = await apiClient.get<PatientswithFiltersResponse>(
    `patient/list/v2${queryString ? `?${queryString}` : ""}`
  );
  return res;
};

const getPatientsForPagination = async () => {
  const res = await apiClient.get<PatientPaginationMappingResponse>(`patient/list/v2/pagination`);
  return res;
};

const getPatientProjects = async (patientId: string) => {
  const res = await apiClient.get<PatientProjectsResponse>(`patient/project/list/${patientId}`);
  return res;
};

export const PatientProfileService = {
  getPatientInfo,
  getPatientsList,
  getPatientListWithBasicInfo,
  getPatientsListByProjectId,
  addPatientsToProject,
  getPatientsListWithFilters,
  getPatientProjects,
  getPatientsForPagination,
};
