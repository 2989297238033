import React from "react";
import { PatientRecommender } from "../..";


interface Props {
  projectId: number;
}

export const ProjectDetailPatientRecommender = ({ projectId }: Props) => {
  return (
    <>
      <PatientRecommender />
    </>
  );
};
