import React from "react";
import { Card } from "antd";
import { Locations as LocationsImg } from "../../../assets/images";

export const Locations = () => {
  return (
    <div>
      <h3 className="basic-detail-title">Locations</h3>
      <Card className="basic-detail-content p-10">
        <img src={LocationsImg} />
      </Card>
    </div>
  );
};
