import { Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  NoResultsFoundMessage,
  PatientCardWrapper,
  PatientDetailCardInfo,
  EVIDENCE_VIEWER_URL,
} from "../tile-helper-components";
import { MedicationHistory } from "../../../../interfaces";

interface Props {
  medication_history: MedicationHistory[];
  patientId: number;
}

export const PatientMedicationHistory = ({ patientId, medication_history }: Props) => {
  return (
    <>
      <PatientCardWrapper title="Medication/RX History">
        <Card className="cyan patient-card">
          <NoResultsFoundMessage
            aboveEightyLength={medication_history.length}
            belowEightyLength={0}
          />
          {medication_history.map((item, index) => (
            <Link
              key={`medication-history-item-${index}`}
              to={`${EVIDENCE_VIEWER_URL}&search=${item.generic}&patient_id=${patientId}`}
            >
              <PatientDetailCardInfo
                key={`medication-history-item-${index}`}
                patientDetailAttribute={{
                  key: item.generic,
                  value: "",
                }}
              />
            </Link>
          ))}
        </Card>
      </PatientCardWrapper>
    </>
  );
};
