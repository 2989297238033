import React from "react";
import { Card, Table } from "antd";
import { InfectiousDiseases } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import "../patientprofile.scss";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
  ViewMoreResults,
  NoResultsFoundMessage,
} from "../tile-helper-components";
import { scoreToPercentage } from "../../../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

interface DataType {
  infectious_disease: string;
  icd10: string;
  score: string;
  key: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: "Infectious Disease",
    dataIndex: "infectious_disease",
    key: "infectious_disease",
  },
  {
    title: "ICD-10 Code",
    dataIndex: "icd10",
    key: "icd10",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
  },
];

interface Props {
  infecitous_disease: InfectiousDiseases;
  patientId: number;
  threshold: number;
}

export const CurrentInfectiousDiseaseCard = ({
  infecitous_disease,
  patientId,
  threshold,
}: Props) => {
  const navigate = useNavigate();
  const { detected_infectious_diseases: diseases, searchable_infectious_diseases } =
    infecitous_disease;
  const sorted = diseases.sort((a, b) => b.score - a.score);
  const aboveEighty = sorted.filter((a) => a.score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.score < threshold / 100);

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title={"Infectious Diseases"}>
      <Card className={"blue patient-card flex"}>
        <Table
          columns={columns}
          rowClassName={"cursor-pointer"}
          onRow={(record) => ({ onClick: () => handleSearchNavigate(record.infectious_disease) })}
          locale={{
            emptyText: (
              <NoResultsFoundMessage
                customMessage={
                  <div className="placeholder-text">
                    <p>{`These specific conditions were not found ${
                      belowEighty.length <= 0 ? "" : `that met the ${threshold}% confidence `
                    }: `}</p>
                    <ul>
                      {searchable_infectious_diseases.map((item, index) => (
                        <li key={`searchable-infectious-disease-${index}`}>{item}</li>
                      ))}
                    </ul>
                  </div>
                }
                aboveEightyLength={aboveEighty.length}
                belowEightyLength={belowEighty.length}
                threshold={threshold}
              />
            ),
          }}
          dataSource={
            aboveEighty
              ? aboveEighty.map((item, index) => ({
                  infectious_disease: item.text,
                  icd10: item.code,
                  score: scoreToPercentage(item.score),
                  key: `infectious-disease-${index}`,
                }))
              : []
          }
          pagination={false}
          className="cmn-table"
        />
        <ViewMoreResults
          searchParanms={{
            search: [...new Set(belowEighty.map((a) => a.text))].join(","),
            patient_id: patientId,
          }}
          belowEightyLength={belowEighty.length}
          threshold={threshold}
        />
      </Card>
    </PatientCardWrapper>
  );
};
