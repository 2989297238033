import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Modal, AutoComplete, Button, Form, Spin } from "antd";
import { AddPatientsToProjectProps } from ".";
import { PatientProfileService, projectService } from "../../../api";
import { openNotificationWithIcon } from "../../../utils";
import { useDispatch } from "react-redux";
import { setProjects } from "../../../store";

type OptionType = { value: string; id: number };

export const AddPatientsToProject = (props: AddPatientsToProjectProps) => {
  const dispatch = useDispatch();
  const {
    handleSuccess,
    handleClose,
    visibility,
    patientIdsToBeAssigned,
    clientId,
    projects,
    assignedToAllProjects,
  } = props;
  const [loading, setLoading] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);
  const [error, setError] = useState("");
  const options: OptionType[] = projects.map((item) => ({
    value: item.Name,
    id: item.Id,
  }));
  useEffect(() => {
    if (assignedToAllProjects) setError("Patient is assigned to all projects");
  }, [assignedToAllProjects]);

  useEffect(() => {
    const fetch = async () => {
      setLoadingProjects(true);
      const { data } = await projectService.getAll(clientId);
      const { data: projectList } = data;
      dispatch(setProjects(projectList));
      setLoadingProjects(false);
    };
    fetch();
  }, [clientId, dispatch]);

  const onSelectChange = (value: string, option: OptionType) => {
    setSelectedOption(option.id);
    setError("");
  };

  const onChange = (value: string) => {
    const find = options.findIndex((item) => item.value === value);
    if (find === -1) setSelectedOption(-1);
  };

  const onSubmit = async () => {
    if (selectedOption !== -1) {
      setLoading(true);
      try {
        await PatientProfileService.addPatientsToProject(clientId, {
          projectId: selectedOption,
          patientIds: [...patientIdsToBeAssigned],
        });
        handleSuccess();
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setLoading(false);
        handleClose();
      }
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={580}
      title="Add Client"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Assign Project</h1>
      </div>
      <div className="auto-complete addClient-content">
        <p>Project Name</p>
        <AutoComplete
          dropdownClassName="align-dropdown"
          options={options}
          onSelect={onSelectChange}
          onChange={onChange}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          notFoundContent={loadingProjects && <Spin tip="Loading..." />}
        />
        {error && <h3 className="error">{error}</h3>}
        <Form.Item className="tr">
          <Button
            className="outline"
            // type="primary"
            onClick={onSubmit}
            disabled={selectedOption === -1}
            loading={loading}
          >
            Assign Project
          </Button>
        </Form.Item>
      </div>
    </Modal>
  );
};
