import { Card } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CONFIDENCE_SCORE_INFO,
  NoResultsFoundMessage,
  PatientCardWrapper,
  PatientDetailCardInfo,
  EVIDENCE_VIEWER_URL,
} from "../tile-helper-components";
import { GeneMutation } from "../../../../interfaces";
import Table, { ColumnsType } from "antd/lib/table";

interface Props {
  geneMutations: GeneMutation[];
  threshold: number;
  patientId: number;
}

interface DataType {
  key: string;
  gene: string;
  status: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Gene",
    dataIndex: "gene",
    key: "gene",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (value) => <div>{value}</div>,
  },
];

export const GeneMutationTile = ({ geneMutations, patientId, threshold }: Props) => {
  const navigate = useNavigate();
  const dataSource: DataType[] = geneMutations.map((item, index) => ({
    gene: item.source_text,
    key: `gene-mutation-item-${index}`,
    status: item.test_value,
  }));

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title={"Gene Mutations"}>
      <Card className="gene-mutations patient-card">
        <Table
          className="two-row"
          columns={columns}
          locale={{
            emptyText: (
              <NoResultsFoundMessage
                aboveEightyLength={geneMutations.length}
                belowEightyLength={0}
              />
            ),
          }}
          dataSource={dataSource}
          pagination={false}
          rowClassName={"cursor-pointer"}
          onRow={(record) => ({ onClick: () => handleSearchNavigate(record.gene) })}
        />
      </Card>
    </PatientCardWrapper>
  );
};
