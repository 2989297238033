import { Col, Button } from "antd";
import React from "react";
import { PatientProjects } from "../../../api";
import "../../../assets/sass/layout/timeline.scss";
import { Loading } from "../../../components";

interface Props {
  projects: PatientProjects[];
  selectedProject: PatientProjects;
  setSelectedProject: (item: PatientProjects) => void;
  patientProjectsLoading: boolean;
}

export const PatientProfileProjects = ({
  projects,
  selectedProject,
  setSelectedProject,
  patientProjectsLoading,
}: Props) => {
  return (
    <Col span={24} className="profile-info-forth-row">
      <h2>Assigned Projects</h2>
      {patientProjectsLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-column gp-10">
          {projects.map((project) => (
            <Button
              key={`patient-project-${project.Id}`}
              className={`timeline ${
                selectedProject.Id === project.Id ? "tab-active" : "tab-inactive"
              }`}
              onClick={() => setSelectedProject(project)}
            >
              {project.Name}
            </Button>
          ))}
        </div>
      )}
    </Col>
  );
};
