import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, UploadProps, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { openNotificationWithIcon } from "../../utils";
import "./file-uploader.scss";

interface Props {
  onSubmit: (file: UploadFile<any>) => Promise<void>;
  onFilesUploadComplete: (files: string[]) => Promise<void>;
  formats?: string[];
}
let acceptedFormats = [".pdf", ".doc", ".txt"];

export const FileUploader = ({ onSubmit, onFilesUploadComplete, formats }: Props) => {
  const [files, setFiles] = useState([] as UploadFile<any>[]);
  const [loading, setLoading] = useState(false);

  if (formats && formats.length > 0) {
    acceptedFormats = formats;
  }
  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: acceptedFormats.join(","),
    beforeUpload: (file, fileList) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setFiles([...fileList, ...files]);
      }
      return isImage;
    },
    onRemove: (file: UploadFile<any>) => {
      if (loading) {
        message.error("Cannot remove file during upload!");
      } else {
        const fileIndex = files.findIndex((item) => item.name === file.name);
        if (fileIndex !== -1) {
          setFiles([...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
      }
    },
    fileList: files,
  };

  const handleFilesSubmit = async () => {
    setLoading(true);
    const uploadedFiles: { uid: string; name: string }[] = [];
    for (const file of files) {
      try {
        await onSubmit(file);
        openNotificationWithIcon("", `${file.name} uploaded successfully!`, "success");
        uploadedFiles.push({ name: file.name, uid: file.uid });
      } catch {
        openNotificationWithIcon("", `Could not upload ${file.name}, please try again!`, "error");
      }
    }
    onFilesUploadComplete(uploadedFiles.map((item) => item.name));
    const uploadedFileUID = uploadedFiles.map((item) => item.uid);
    setFiles([...files.filter((file) => !uploadedFileUID.includes(file.uid))]);
    setLoading(false);
  };

  return (
    <div className="attachment">
      <Dragger {...props}>
        <div className="attachment-Content">
          <CloudUploadOutlined />
          <p>
            Drag & drop file here or <span>Browse File</span>
          </p>
          {acceptedFormats.length > 0 && (
            <p className="italic">You can upload only {acceptedFormats.join(",")} files</p>
          )}
        </div>
      </Dragger>
      <div className="uploadButton flex jce">
        <Button
          className="outline"
          disabled={files.length <= 0}
          onClick={handleFilesSubmit}
          loading={loading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
