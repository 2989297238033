import { Spin } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EvidencePageResponseInfo } from "../../../api";
import { PAGE_URL, isEmptyObject } from "../../../utils";
import { BackButton } from "../../../components";
import "./evidence-viewer-aside.scss";

interface EvidencePageHeaderProps {
  currPage: number;
  pageInfo: EvidencePageResponseInfo;
}

export const EvidencePageHeader = ({ pageInfo, currPage }: EvidencePageHeaderProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="evidence-viewer">
        {isEmptyObject(pageInfo) ? (
          <Spin tip="Loading..."></Spin>
        ) : (
          <>
            <BackButton />
            <div className="e-content-row">
              <div
                className="e-content-row-first"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`${PAGE_URL.PATIENT_PROFILE}/${pageInfo.patient.patientId}`)
                }
              >
                <p>{pageInfo.patient.name}</p>
              </div>
              <div className="e-content-row-second">
                <div className="flex no-wrap">
                  <h5>Patient ID</h5>
                  <span>{pageInfo.patient.mrn}</span>
                </div>
              </div>
              <div className="e-content-row-third">
                <h5>Page No.</h5>
                <span>{currPage}</span>
                <div className="flex no-wrap">
                  <h5>File Name</h5>
                  <span>{pageInfo.patient.fileName}</span>
                </div>
              </div>
              {/* <div>
                <p className="e-content-title">File Name</p>
                <h3>{pageInfo.patient.fileName}</h3>
              </div> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};
