import { Card } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CONFIDENCE_SCORE_INFO,
  NoResultsFoundMessage,
  PatientCardWrapper,
  EVIDENCE_VIEWER_URL,
  ViewMoreResults,
} from "../tile-helper-components";
import { CancerStaging } from "../../../../interfaces";
import { scoreToPercentage } from "../../../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";

interface DataType {
  key: string;
  cancerStage: string;
  score: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Cancer Stage",
    dataIndex: "cancerStage",
    key: "cancerStage",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
    render: (value) => <div>{value}</div>,
  },
];

interface Props {
  cancerStaging: CancerStaging[];
  threshold: number;
  patientId: number;
}

export const CancerStagingTile = ({ cancerStaging, patientId, threshold }: Props) => {
  const sorted = cancerStaging.sort((a, b) => b.score - a.score);
  const aboveEighty = sorted.filter((a) => a.score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.score < threshold / 100);
  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title={"Cancer Staging"}>
      <Card className="patient-card">
        <Table
          className="two-row"
          columns={columns}
          locale={{
            emptyText: (
              <NoResultsFoundMessage
                aboveEightyLength={aboveEighty.length}
                belowEightyLength={belowEighty.length}
                threshold={threshold}
              />
            ),
          }}
          dataSource={
            aboveEighty
              ? aboveEighty.map((item, index) => ({
                  cancerStage: item.cancer_stage,
                  score: scoreToPercentage(item.score),
                  key: `cancer-staging-item-${index}`,
                }))
              : []
          }
          pagination={false}
          rowClassName={"cursor-pointer"}
          onRow={(record) => ({ onClick: () => handleSearchNavigate(record.cancerStage) })}
        />
        <ViewMoreResults
          searchParanms={{
            search: [...new Set(belowEighty.map((a) => a.cancer_stage))].join(","),
            patient_id: patientId,
          }}
          belowEightyLength={belowEighty.length}
          threshold={threshold}
        />
      </Card>
    </PatientCardWrapper>
  );
};
