export * from "./PatientInsightAside";
export * from "./PatientInsightContainer";

export const PATIENT_INSIGHT_TABS = {
  PATIENT_INSIGHTS: "Patient Insights",
  PROJECT_SPECIFIC_INSIGHTS: "Project Specific Insights",
};

export interface PatientDetailCardProps {
  heading: string;
  value: string;
}
