import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table } from "antd";
import { Condition } from "../../../../interfaces";
import "../patientprofile.scss";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
  ViewMoreResults,
  NoResultsFoundMessage,
} from "../tile-helper-components";
import { scoreToPercentage } from "../../../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

interface DataType {
  condition: string;
  icd10: string;
  score: string;
  key: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Condition",
    dataIndex: "condition",
    key: "condition",
  },
  {
    title: "ICD-10 Code",
    dataIndex: "icd10",
    key: "icd10",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
  },
];

interface Props {
  conditions: Condition[];
  threshold: number;
  patientId: number;
}

export const ConditionsCard = ({ conditions, patientId, threshold }: Props) => {
  const sorted = conditions.sort((a, b) => b.score - a.score);
  const aboveEighty = sorted.filter((a) => a.score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.score < threshold / 100);

  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <>
      <PatientCardWrapper title={"Conditions"}>
        <Card className="blue flex patient-card">
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <NoResultsFoundMessage
                  aboveEightyLength={aboveEighty.length}
                  belowEightyLength={belowEighty.length}
                  threshold={threshold}
                />
              ),
            }}
            dataSource={
              aboveEighty
                ? aboveEighty.map((item, index) => ({
                    condition: item.text,
                    icd10: item.code,
                    score: scoreToPercentage(item.score),
                    key: `patient-condition-${index}`,
                  }))
                : []
            }
            pagination={false}
            className="cmn-table"
            rowClassName={"cursor-pointer"}
            onRow={(record) => ({ onClick: () => handleSearchNavigate(record.condition) })}
          />
          <ViewMoreResults
            searchParanms={{
              search: [...new Set(belowEighty.map((a) => a.text))].join(","),
              patient_id: patientId,
            }}
            belowEightyLength={belowEighty.length}
            threshold={threshold}
          />
        </Card>
      </PatientCardWrapper>
    </>
  );
};
