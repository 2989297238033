import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { projectService, RecentDocument } from "../../api";
import { getDocumentListColumns, InfiniteScrollTable } from "../../components";

interface Props {
  projectId: number;
}

export const RecentDocuments = ({ projectId }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileColumns = getDocumentListColumns(dispatch, navigate);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([] as RecentDocument[]);
  const [nextPageIndexKey, setNextPageIndexKey] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const documentsList: DocumentsColumnDataType[] = list.map((item) => {
    const {
      documentCompletedOn,
      documentCreatedOn,
      documentId,
      documentStatus,
      objectName,
      statusList,
    } = item;
    return {
      key: documentId,
      name: objectName,
      completedOn: documentCompletedOn && documentCompletedOn.split(" ")[0],
      createdOn: documentCreatedOn && documentCreatedOn.split(" ")[0],
      status: documentStatus,
      action: "",
      statusList,
    };
  });

  const fetchFiles = useCallback(
    async (indexKey = "", isAppend = false) => {
      if (projectId) {
        setLoading(true);
        const { data } = await projectService.getFiles(projectId, 20, indexKey);
        setLoading(false);
        if (isAppend) return data;
        if (data.response.list.length === 0) setHasMore(false);
        setList(data.response.list);
        setNextPageIndexKey(
          data.response.LastEvaluatedKey ? data.response.LastEvaluatedKey.documentId : ""
        );
      }
    },
    [projectId]
  );

  const appendFiles = async () => {
    const data = await fetchFiles(nextPageIndexKey, true);
    if (data && data.response.LastEvaluatedKey) {
      setList(list.concat(data.response.list));
      setNextPageIndexKey(data.response.LastEvaluatedKey.documentId);
    } else setHasMore(false);
  };

  // const handleRowClick = (item: DocumentsColumnDataType) => {
  //   navigate(`${PAGE_URL.EVIDENCE_VIEWER}?fileId=${item.key}`);
  // };

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <div className="recent-document">
      <h2 className="basic-detail-title">Recent Documents {list.length ? `(${list.length})` : ""}</h2>
      <div className="client-table basic-detail-content">
        <InfiniteScrollTable
          columns={fileColumns}
          dataSource={documentsList}
          onUpdate={appendFiles}
          pagination={false}
          hasMore={hasMore}
          loading={loading}
          // onRow={(record) => {
          //   return {
          //     onClick: () => handleRowClick(record),
          //   };
          // }}
        />
      </div>
    </div>
  );
};
