import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import {
  PatientInsightConfigurations,
  SETTINGS_CONTAINER_TABS,
  AssignUsersToProjects,
} from "./settings-page-container";
import { ProjectsList } from "../tables";

export const SettingsPageContainer = () => {
  const [selectedTab, setSelectedTab] = useState(SETTINGS_CONTAINER_TABS.PROJECTS_LIST);
  const [component, setComponent] = useState(<></>);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    let res = <></>;

    switch (selectedTab) {
      case SETTINGS_CONTAINER_TABS.PROJECTS_LIST:
        res = <ProjectsList />;
        break;
      case SETTINGS_CONTAINER_TABS.PATIENT_INSIGHT_CONFIG:
        res = <PatientInsightConfigurations />;
        break;
      case SETTINGS_CONTAINER_TABS.ASSIGN_USERS_TO_PROJECTS:
        res = <AssignUsersToProjects />;
        break;
    }
    setComponent(res);
  }, [selectedTab]);

  return (
    <>
      <div className="flex jcsb bd-bt">
        <div className="tabs no-border">
          <Tabs onChange={onChange} defaultActiveKey={selectedTab}>
            {Object.values(SETTINGS_CONTAINER_TABS).map((key) => (
              <Tabs.TabPane tab={key} key={key} />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="tabs-content">{component}</div>
    </>
  );
};
