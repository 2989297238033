import { Annotation, AnnotationFilePageContent } from "../../../interfaces";

export const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string;
  fileName: string;
  fileType: string;
}) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const getPageContent = (pageContents:AnnotationFilePageContent[][], pageNumber: number) => {
  let pageContent = "";
    if (pageContents.length >= pageNumber) {
      const content = pageContents[pageNumber - 1];
      for (const res of content) {
        pageContent += res.str + " ";
      }
      } 
      return pageContent;
}

export const groupByContent = (exportJSON:Annotation[], pageContents:AnnotationFilePageContent[][]) =>{
  const pageContentsArr = [];
  
  Object.keys(pageContents).map((key:any) => {
    
  });
  const groupByPage = exportJSON.reduce((group:any, data:any) => {
    const {page} = data;
    group[page] = group[page] ?? {content: getPageContent(pageContents, page), tokens: []};
    group[page].tokens.push(data);
    return group;
  }, new Map());

return  Object.keys(groupByPage).map((key:any) => {
    return {"content": groupByPage[key].content, "tokens" : groupByPage[key].tokens.map((item:any) => ({...item, content: groupByPage[key].content}))};
  });
 
  // return Object.keys(groupByPage).map((key:any) => {
  //   return {"content": key, "tokens" : groupByPage[key]};
  // });
}

export const exportToIOBTSV = (jsonData:any, delimeter:string) => {
    const columns  = `-DOCSTART-X-O-O${delimeter}`;
    const headers = [columns];
    const annotatedDataCsv =  jsonData.data.data.reduce((data:any[], annotateData:any) => {
        data.push([`"${annotateData.text}"`, annotateData.iob_tag])
      return data;
    }, []);  

    downloadFile({
      data: [...headers, ...annotatedDataCsv].join("\n"),
      fileName: "result.csv",
      fileType: "text/csv",
    });
}

export const exportToCsv = (annotatorValue: Annotation[], delimeter: string) => {
  const columns = `start${delimeter}end${delimeter}text${delimeter}tag${delimeter}color${delimeter}category${delimeter}page`;
  // Headers for each column
  const headers = [columns];
  // Convert users data to a csv
  const annotateDataCsv = annotatorValue.reduce((data: string[], annotateData) => {
    data.push(
      [
        annotateData.start,
        annotateData.end,
        annotateData.text,
        annotateData.tag,
        annotateData.color,
        annotateData.category,
        annotateData.page,
      ].join(delimeter)
    );
    return data;
  }, []);  

  downloadFile({
    data: [...headers, ...annotateDataCsv].join("\n"),
    fileName: "result.csv",
    fileType: "text/csv",
  });
};
