import React from "react";
import { Col } from "antd";
import { PatientDetailCardProps } from "..";

interface Props {
  patientInfo: {
    mrn: string;
    source: string;
  };
  reviewStatusMap: PatientDetailCardProps[];
}

const PatientDetailCard = (detail: PatientDetailCardProps) => {
  return (
    <div>
      <h5>{detail.heading}</h5>
      <span className="profile-info-desc">{detail.value ? detail.value : "-"}</span>
    </div>
  );
};

export const PatientProfileInfo = ({ patientInfo, reviewStatusMap }: Props) => {
  const { mrn, source } = patientInfo;
  const patientDetailMap = [
    { heading: "Patient ID", value: mrn },
    { heading: "Source (Provider/System)", value: source ? source : "System" },
  ];

  return (
    <Col span={24}>
      <div className="profile-info-second-row">
        {/* <div className="patient-info-group"> */}
        {patientDetailMap.map((detail, index) => (
          <PatientDetailCard key={`patient-detail-${index}`} {...detail} />
        ))}
        {/* </div> */}
      </div>
      {reviewStatusMap.length > 0 && (
        <div className="profile-info-third-row">
          {reviewStatusMap.map((detail, index) => (
            <PatientDetailCard key={`patient-review-detail-${index}`} {...detail} />
          ))}
        </div>
      )}
    </Col>
  );
};
