import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { NSCLCCancer } from "../../../../interfaces";
import { scoreToPercentage } from "../../../../utils";
import "../patientprofile.scss";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  ViewMoreResults,
  NoResultsFoundMessage,
  PatientCardWrapper,
} from "../tile-helper-components";
import { InfoCircleOutlined } from "@ant-design/icons";

interface DataType {
  key: string;
  nsclc: string;
  score: string;
  diagnosis: string;
  histology: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "NSCLC Diagnosis",
    dataIndex: "nsclc",
    key: "nsclc",
  },
  {
    title: "Date of Diagnosis",
    dataIndex: "diagnosis",
    key: "diagnosis",
  },
  {
    title: "Histology",
    dataIndex: "histology",
    key: "histology",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
    render: (value) => <div>{value}</div>,
  },
];

interface Props {
  nsclcCancer: NSCLCCancer[];
  threshold: number;
  patientId: number;
}

export const NSCLCDiagnosisTile = ({ nsclcCancer, patientId, threshold }: Props) => {
  const sorted = nsclcCancer.sort((a, b) => b.score - a.score);
  const aboveEighty = sorted.filter((a) => a.score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.score < threshold / 100);
  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <>
      <PatientCardWrapper title={"Non-Small Cell Lung Cancer"}>
        <Card>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <NoResultsFoundMessage
                  aboveEightyLength={aboveEighty.length}
                  belowEightyLength={belowEighty.length}
                  threshold={threshold}
                />
              ),
            }}
            dataSource={
              aboveEighty
                ? aboveEighty.map((item, index) => ({
                    diagnosis: item.date_of_diagnosis,
                    histology: item.histology,
                    nsclc: item.nsclc_diagnosis,
                    score: scoreToPercentage(item.score),
                    key: `patient-nsclc-cancer-${index}`,
                  }))
                : []
            }
            pagination={false}
            className="nsclc-table"
            rowClassName={"cursor-pointer"}
            onRow={(record) => ({ onClick: () => handleSearchNavigate(record.nsclc) })}
          />
          <ViewMoreResults
            searchParanms={{
              search: [...new Set(belowEighty.map((a) => a.nsclc_diagnosis))].join(","),
              patient_id: patientId,
            }}
            belowEightyLength={belowEighty.length}
            threshold={threshold}
          />
        </Card>
      </PatientCardWrapper>
    </>
  );
};
