import React from "react";
import { useUser } from "../../hooks";
import { USER_ROLES_CODE } from "../../utils";
import ClinicianAndDataAdminDashboard from "./ClinicianAndDataAdmin";
import EnterpriseAdminDashboard from "./EnterpriseAdmin";
import SuperAdminDashboard from "./SuperAdmin";

const Dashboard = () => {
  const { roleCode } = useUser();

  return (
    <>
      {roleCode === USER_ROLES_CODE.SUPER_ADMIN ? (
        <SuperAdminDashboard />
      ) : roleCode === USER_ROLES_CODE.ENTERPRISE_ADMIN ? (
        <EnterpriseAdminDashboard />
      ) : (
        <ClinicianAndDataAdminDashboard />
      )}
    </>
  );
};
export default Dashboard;
