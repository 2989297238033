import React from "react";
import { ProjectUsers } from "../..";
import { ProjectDetail } from "../../../interfaces";

interface Props {
  projectDetail: ProjectDetail;
}

export const ProjectDetailUsers = ({ projectDetail }: Props) => {
  return (
    <>
      <ProjectUsers projectDetail={projectDetail} />
    </>
  );
};
