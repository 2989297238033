import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setUserList, setUserRoles } from "../../store";
import { User, usersService } from "../../api";
import { UsersList } from "..";
import { rolesService } from "../../api/roles";
import "../../pages/dashboard/superAdmin.scss";
import { useFetchUserList } from "../../hooks";

export const EnterpriseAdminDashboardContainer = () => {
  const dispatch = useDispatch();
  const { fetchUsers } = useFetchUserList();
  const [loading, setLoading] = useState(true);
  const clientId = useSelector((state: RootState) => state.user.user.clientId);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await fetchUsers();
      const { data: userRoles } = await rolesService.getAll(true);
      dispatch(setUserRoles(userRoles.data));
      setLoading(false);
    };
    fetch();
  }, [clientId, dispatch, fetchUsers]);

  return (
    <div className="projectsList">
      <UsersList loading={loading} />
    </div>
  );
};
