import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Button, Card } from "antd";
import { AdvanceSearchModal } from "../../modals/advance-search/AdvanceSearch";
import { RootState, setAdvanceSearchParams } from "../../../store";
import { PAGE_URL, toTitleCase } from "../../../utils";
import { AdvanceSearchParams, searchService } from "../../../api";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { AddRemoveTags, Loading } from "../../../components";
import "./search-form.scss";

interface Props {
  setCallApi: Dispatch<SetStateAction<boolean>>;
}

export const SearchForm = ({ setCallApi }: Props) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useSearchParams();
  const { advanceSearchParams } = useSelector((state: RootState) => state.search);
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [loadingSearchParams, setLoadingSearchParams] = useState(false);
  const [searchType, setSearchType] = useState([{ key: "All", status: "enable" }]);
  const [check, setCheck] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState(
    queryParams.get("type") || searchType[0].key
  );
  const [searchKeywords, setSearchKeywords] = useState(queryParams.get("search")?.split(",") || []);

  const autoCompleteOptions = useMemo(() => {
    if (!advanceSearchParams.Diseases) return [];
    return advanceSearchParams.Diseases.data.map((item) => ({ value: item }));
  }, [advanceSearchParams]);

  useEffect(() => {
    if (check) {
      const params = Object.fromEntries(queryParams.entries());
      const newQueryParams = {
        type: selectedSearchType,
        search: searchKeywords.join(","),
        gender: params["gender"] || "Both",
        ageGroup: params["ageGroup"] || ["All"],
        location: params["location"] || "All Countries",
        interventions: params["interventions"] || ["All"],
      };
      setQueryParams(newQueryParams);
      if (searchKeywords.length === 0) navigate(PAGE_URL.SEARCH.HOME);
      else {
        navigate(
          {
            search: createSearchParams(newQueryParams).toString(),
          },
          { replace: true }
        );
      }
      setCheck(false);
    }
  }, [navigate, searchKeywords, selectedSearchType, setQueryParams, check, queryParams]);

  useEffect(() => {
    const getAdvanceSearchParams = async () => {
      setLoadingSearchParams(true);
      const res = await searchService.getAdvanceSearchParams();
      const { Diseases, Type, Gender, Interventions, Locations, ageGroup } = res;
      const searchParams: AdvanceSearchParams = {
        Diseases: { ...Diseases, data: [...new Set(Diseases.data.map((i) => toTitleCase(i)))] },
        Type: [{ key: "All", status: "enable" }, ...Type],
        Gender: [{ key: "Both", status: "enable" }, ...Gender],
        Locations: ["All Countries", ...Locations.map((loc) => loc.toUpperCase())],
        ageGroup: { ...ageGroup, data: [{ key: "All", status: "enable" }, ...ageGroup.data] },
        Interventions: {
          ...Interventions,
          data: [{ key: "All", status: "enable" }, ...Interventions.data],
        },
      };
      dispatch(setAdvanceSearchParams(searchParams));
      setLoadingSearchParams(false);
    };
    if (Object.keys(advanceSearchParams).length <= 0) getAdvanceSearchParams();
    else setSearchType([...advanceSearchParams.Type]);
  }, [advanceSearchParams, dispatch]);

  const handleChange = (values: string[]) => {
    setCheck(true);
    if (values.length > 0) {
      const ele = values[values.length - 1];
      let insertedElement = undefined;
      insertedElement = searchKeywords.find((item) => item === ele);
      if (insertedElement) {
        const finalList = [...searchKeywords];
        const index = searchKeywords.indexOf(insertedElement);
        finalList.splice(index, 1);
        setSearchKeywords([...finalList]);
        return;
      }
    }
    setSearchKeywords([...values]);
  };

  const onTagsChange = (values: string[]) => {
    setCheck(true);
    setSearchKeywords([...values]);
  };

  useEffect(() => {
    const params = Object.fromEntries(queryParams.entries());
    const array = params.search ? [...new Set(params.search.split(","))] : [];
    setSearchKeywords([...array]);
    setSelectedSearchType(params.type || "All");
  }, [queryParams]);

  return (
    <>
      {Object.keys(advanceSearchParams).length > 0 && isAdvanceSearch && (
        <AdvanceSearchModal
          modalState={{ visible: isAdvanceSearch, setVisible: setIsAdvanceSearch }}
          searchParams={advanceSearchParams}
        />
      )}
      <div>
        <h2 className="card-content-title">AI-Assisted Deep Search Engine</h2>
        <Card className="card-content">
          <p className="breif-desc">
            Search all documents and patients for medical conditions, encounters, medications,
            procedures, lab results and biomarkers, use advanced filters to narrow your search.
          </p>
          <div className="flex w-full input-wrap jcc aic search-dropdown">
            <Input.Group compact>
              <Select
                value={selectedSearchType}
                dropdownClassName="search-drodpown select tc"
                onChange={(e) => {
                  setSelectedSearchType(e);
                  setCheck(true);
                }}
              >
                {searchType.map((item, index) => (
                  <Option key={`search-type-${index}`} value={item.key}>
                    {toTitleCase(item.key)}
                  </Option>
                ))}
              </Select>
              <Select
                dropdownClassName="search-drodpown auto-complete"
                mode="tags"
                placeholder="Search all projects or patients"
                // maxTagCount={0}
                // maxTagPlaceholder="Search all projects or patients"
                value={searchKeywords}
                onChange={handleChange}
                clearIcon={<CloseOutlined />}
                options={autoCompleteOptions}
                loading={loadingSearchParams}
                onDeselect={(value: string) => handleChange([value])}
                notFoundContent={loadingSearchParams && <Loading />}
              />
            </Input.Group>
            <Button
              // type="primary"
              // icon={<SearchOutlined />}
              className="outline"
              disabled={searchKeywords.length === 0}
              onClick={() => {
                navigate({ pathname: PAGE_URL.SEARCH.RESULT, search: window.location.search });
                setCallApi(true);
              }}
            >
              Search
            </Button>
            <p className="advance-search" onClick={() => setIsAdvanceSearch(!isAdvanceSearch)}>
              <a>Advanced Search</a>
            </p>
          </div>
          <div className="selected-tag flex customize-ant-tag">
            <AddRemoveTags {...{ tags: searchKeywords, setTags: onTagsChange }} />
          </div>
        </Card>
      </div>
    </>
  );
};
