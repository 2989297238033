import React from "react";
import { useDispatch } from "react-redux";
import { Button, Table } from "antd";
import { getUserColumns } from "../../components";
import { ProjectDetail } from "../../interfaces";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";

interface Props {
  projectDetail: ProjectDetail;
}

export const ProjectUsers = ({ projectDetail }: Props) => {
  const dispatch = useDispatch();
  const userColumns = getUserColumns(dispatch, false);
  const usersData: UserColumnDataType[] = projectDetail.users
    ? projectDetail.users.map((item) => {
        const { email, id, first_name, last_name, status, relationId, visualName } = item;
        return {
          key: id,
          name: first_name + " " + last_name,
          email,
          action: "",
          status,
          relationId,
          role: visualName,
        };
      })
    : [];

  return (
    <div className="users">
      <div className="flex jcsb aic">
        <p className="table-heading">Users</p>
        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_USER_TO_PROJECT));
          }}
          disabled={!projectDetail.status}
        >
          Add team members to project
        </Button>
      </div>
      <div className="user-table">
        <Table
          dataSource={usersData}
          columns={userColumns}
          // loading={loading}
          pagination={false}
        ></Table>
      </div>
    </div>
  );
};
