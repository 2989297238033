import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PatientProfileService } from "../api";
import { IPatient } from "../interfaces";
import { useFetchPatientProjects } from "../hooks";
import {
  PageWrapper,
  PatientInsightAside,
  PatientInsightContainer,
  PatientDetailCardProps,
} from "../containers";
import { getLocalDateTimeStringFromISO, isEmptyObject } from "../utils";
import "../containers/patient-insight/patient-insight-container/patientprofile.scss";
import { useSelector } from "react-redux";
import { RootState } from "../store/index";

interface Props {
  patientId: string;
}

export const PatientInsights = ({ patientId }: Props) => {
  const [searchParams] = useSearchParams();
  const {
    fetchPatientProjects,
    patientProjectsLoading,
    patientProjects,
    selectedProject,
    setSelectedProject,
  } = useFetchPatientProjects();
  const [patientInfo, setPatientInfo] = useState({} as IPatient);
  const [loading, setLoading] = useState(false);
  const projectId = searchParams.get("projectId");
  const [reviewStatusMap, setReviewStatusMap] = useState([] as PatientDetailCardProps[]);
  const { isProjectAssigned } = useSelector((state: RootState) => state.project);

  useEffect(() => {
    const projectStatusInfo = patientInfo.projectStatusInfo;
    if (projectId && !isEmptyObject(projectStatusInfo)) {
      setReviewStatusMap([
        {
          heading: "Reviewed By",
          value: projectStatusInfo?.userInfo?.updatedBy
            ? projectStatusInfo?.userInfo.updatedBy
            : "",
        },
        {
          heading: "Reviewed On",
          value: projectStatusInfo?.userInfo?.updatedAt
            ? getLocalDateTimeStringFromISO(projectStatusInfo?.userInfo?.updatedAt)
            : "",
        },
      ]);
    } else setReviewStatusMap([]);
  }, [projectId, patientInfo.projectStatusInfo]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await fetchPatientProjects(patientId);
      const { data } = await PatientProfileService.getPatientInfo(patientId, projectId);
      setPatientInfo(data);
      setLoading(false);
    };
    fetch();
  }, [fetchPatientProjects, patientId, projectId]);

  useEffect(() => {
    if (isProjectAssigned) fetchPatientProjects(patientId);
  }, [fetchPatientProjects, isProjectAssigned, patientId]);

  return (
    <PageWrapper
      loading={loading}
      pageLeftAsideChildren={
        <PatientInsightAside
          {...{
            patientInfo,
            patientProjects,
            selectedProject,
            setSelectedProject,
            reviewStatusMap,
            patientProjectsLoading,
          }}
        />
      }
      pageContainerChildren={
        <PatientInsightContainer
          {...{ selectedProject, patientProjects, patientInfo, setReviewStatusMap }}
        />
      }
    />
  );
};

const PatientInsightWrapper = () => {
  const { patientId } = useParams();
  return <>{patientId && <PatientInsights patientId={patientId} />}</>;
};

export default PatientInsightWrapper;
