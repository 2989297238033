import { Select } from "antd";
import React from "react";
import { PatientStatusDropdownOption } from "../interfaces";

interface Props {
  value?: string;
  defaultValue?: string;
  options: PatientStatusDropdownOption[];
  loading: boolean;
  onSelect: (value: string, option: PatientStatusDropdownOption) => void;
  notFoundContent?: React.ReactNode;
}

export const PatientStatusDropdown = (props: Props) => {
  return (
    <Select
      style={{ width: 210 }}
      className="ai-select"
      getPopupContainer={(trigger) => trigger.parentNode}
      dropdownMatchSelectWidth={true}
      {...props}
    ></Select>
  );
};
