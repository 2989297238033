import React from "react";
import { IconsProps } from "./Icons";

export const GenderIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 12 28"}
      width={width ? width : "12"}
      height={height ? height : "28"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        d="M5.60029 5.6C7.14669 5.6 8.40029 4.3464 8.40029 2.8C8.40029 1.2536 7.14669 0 5.60029 0C4.0539 0 2.80029 1.2536 2.80029 2.8C2.80029 4.3464 4.0539 5.6 5.60029 5.6Z"
        fill="currentcolor"
      />
      <path
        d="M9.8 7H1.4C1.0287 7 0.672602 7.1475 0.410051 7.41005C0.1475 7.6726 0 8.0287 0 8.4V18.2H2.8V28H8.4V18.2H11.2V8.4C11.2 8.0287 11.0525 7.6726 10.7899 7.41005C10.5274 7.1475 10.1713 7 9.8 7Z"
        fill="currentcolor"
      />
    </svg>
  );
};
