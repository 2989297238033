import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersService } from "../../api";
import { getUserColumns } from "../../components";
import { deleteUser, openModal, RootState, updateDeleteConfirmation } from "../../store";
import { MODAL_TYPE, USER_ROLES_CODE } from "../../utils";

interface Props {
  loading: boolean;
}

export const UsersList = ({ loading: initialLoading }: Props) => {
  const dispatch = useDispatch();
  const managersColumns = getUserColumns(dispatch);
  const { id: userId, list } = useSelector((state: RootState) => state.data.users).users;
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [loading, setLoading] = useState(false);

  const managersData: UserColumnDataType[] = list.map((item) => {
    const { email, id, firstName, lastName, status, visualName: role } = item;
    return {
      key: id,
      name: firstName + " " + lastName,
      email,
      action: "",
      status,
      role,
    };
  });

  useEffect(() => {
    const deleteUserAPI = async () => {
      setLoading(true);
      await usersService.deleteOne(userId);
      dispatch(deleteUser({ id: userId }));
      dispatch(updateDeleteConfirmation(false));
      setLoading(false);
    };

    if (isDeleteConfirmed && userId !== -1) deleteUserAPI();
  }, [userId, dispatch, isDeleteConfirmed]);

  return (
    <>
      <div className="flex jcsb aic ">
        <p className="table-heading">{"Users"}</p>
        <Button
          // type="primary"
          // icon={<PlusOutlined />}
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
          }}
        >
          {`Add New User`}
        </Button>
      </div>
      <Table
        className="user-table"
        pagination={false}
        dataSource={managersData}
        columns={managersColumns}
        loading={loading || initialLoading}
      ></Table>
    </>
  );
};
