import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, UploadProps, message } from "antd";
import { UploadFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import React, { useState } from "react";
import { projectService } from "../../../api";
import { openNotificationWithIcon } from "../../../utils";
import "./file-uploader.scss";
interface Props {
  projectId: number;
}
const acceptedFormats = [".pdf", ".doc", ".txt"];

export const FileUploader = ({ projectId }: Props) => {
  const [files, setFiles] = useState([] as UploadFile<any>[]);
  const [loading, setLoading] = useState(false);

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: acceptedFormats.join(","),
    beforeUpload: (file, fileList) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setFiles([...fileList, ...files]);
      }
      return isImage;
    },
    onRemove: (file: UploadFile<any>) => {
      if (loading) {
        message.error("Cannot remove file during upload!");
      } else {
        const fileIndex = files.findIndex((item) => item.name === file.name);
        if (fileIndex !== -1) {
          setFiles([...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)]);
        }
      }
    },
    fileList: files,
  };

  const handleFilesSubmit = async () => {
    setLoading(true);
    const filesUploadedSuccessFully = [] as string[];
    for (const file of files) {
      try {
        const { data } = await projectService.getS3UploadURL(projectId, file.name);
        const res = await projectService.uploadFiles(data.response, file);
        openNotificationWithIcon("", `${file.name} uploaded successfully!`, "success");
        filesUploadedSuccessFully.push(file.uid);
      } catch {
        openNotificationWithIcon("", `Could not upload ${file.name}, please try again!`, "error");
      }
    }
    setFiles([...files.filter((file) => !filesUploadedSuccessFully.includes(file.uid))]);
    setLoading(false);
  };

  return (
    <div className="attachment">
      <h2 className="basic-detail-title">Attachment</h2>
      <Dragger {...props}>
        <div className="attachment-Content">
          <CloudUploadOutlined />
          <p>
            Drag & drop file here or <span>Browse File</span>
          </p>
          <p className="italic">You can upload only .doc, .pdf or .txt files</p>
          {/* <h3>Upload single file only</h3> */}
        </div>
      </Dragger>
      <div className="uploadButton flex jce">
        <Button
          // type="primary"
          className="outline"
          disabled={files.length <= 0}
          onClick={handleFilesSubmit}
          loading={loading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
