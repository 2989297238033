import axios, { AxiosRequestConfig } from "axios";
import { store } from "../App";
import { logoutUser } from "../store";
import { PAGE_URL } from "../utils";

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "x-access-token";
  headers[namespace] = token;
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().user.user.accessToken;
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const instance = axios.create(defaultOptions);

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  (error: any) => {
    // handle user session refresh here
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(logoutUser());
      window.location.replace(PAGE_URL.LOGIN)
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    return Promise.reject(error);
  }
);

export default instance;
