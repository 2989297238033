export const CATEGORY = [
  {
    id: "ANATOMY",
    name: "Anotomy",
    color: "#E8AE3D",
    options: [{ id: "SYSTEM_ORGAN_SITE", name: "System Organ Site" },
    { id: "DIRECTION", name: "Direction" }],
  },
  {
    id: "MEDICAL_CONDITION",
    name: "Medical Condition",
    color: "#6077E1",
    options: [
      { id: "DX_NAME", name: "DX Name" },
      { id: "ACUITY", name: "Acuity" },
      { id: "DIAGNOSIS", name: "Diagnosis" },
      { id: "NEGATION", name: "Negation" },
      { id: "SIGN", name: "Sign" },
      { id: "SYMPTOM", name: "Symptom" }
    ],
  },
  {
    id: "MEDICATION",
    name: "Medication",
    color: "#00CACD",
    options: [
      { id: "BRAND_NAME", name: "Brand Name" },
      { id: "GENERIC_NAME", name: "Generic Name" },
      { id: "DOSAGE", name: "Dosage" },
      { id: "DURATION", name: "Duration" },
      { id: "FORM", name: "Form" },
      { id: "FREQUENCY", name: "Frequency" },
      { id: "RATE", name: "Rate" },
      { id: "ROUTE_OR_MODE", name: "Route OR Mode" },
      { id: "STRENGTH", name: "Strength" },
      { id: "NEGATION", name: "Negation" },
    ],
  },
  {
    id: "PROTECTED_HEALTH_INFORMATION",
    name: "Protected Health Information",
    color: "#95CB7A",
    options: [
      { id: "ADDRESS", name: "Address" },
      { id: "AGE", name: "Age" },
      { id: "EMAIL", name: "Email" },
      { id: "ID", name: "Id" },
      { id: "NAME", name: "Name" },
      { id: "DATE", name: "Date" },
      { id: "PHONE_OR_FAX", name: "Phone OR Fax" },
      { id: "PROFESSION", name: "Profession" },
    ],
  },
  {
    id: "TEST_TREATMENT_PROCEDURE",
    name: "Test Treatment Procedure",
    color: "#9B67C6",
    options: [
      { id: "PROCEDURE_NAME", name: "Procedure Name" },
      { id: "TEST_NAME", name: "Test Name" },
      { id: "TREATMENT_NAME", name: "Treatment Name" },
      { id: "TEST_VALUE", name: "Test Value" },
      { id: "TEST_UNIT", name: "Test Unit" },
    ],
  },
  {
    id: "TIME_EXPRESSION",
    name: "Time Expression",
    color: "#03868E",
    options: [
      { id: "TIME_TO_MEDICATION_NAME", name: "Time To Medication Name" },
      { id: "TIME_TO_DX_NAME", name: "Time To DX Name" },
      { id: "TIME_TO_TEST_NAME", name: "Time To Test Name" },
      { id: "TIME_TO_PROCEDURE_NAME", name: "Time To Procedure Name" },
      { id: "TIME_TO_TREATMENT_NAME", name: "Time To Treatment Name" },
    ],
  },
];

export const EXPORT_OPTIONS = [
  { id: "JSON", name: "json" },
  { id: "CSV", name: "CSV" },
  { id: "TSV", name: "TSV" },
  { id: "IOB", name: "IOB" },
];
