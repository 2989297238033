import React from "react";
import { ProjectStatus } from "../../../components";
import { YourProgress } from "./your-progress/YourProgress";
import { Row, Col, Card } from "antd";
import ChartGreenIcon from "../../../assets/icons/ChartGreenIcon";
import { DashboardData } from "../../../api";
import "./sidebar.scss";

interface Props {
  data: DashboardData;
}

export const Sidebar = ({ data }: Props) => {
  return (
    <aside className="d-sidebar">
      {/* <style>{css}</style> */}
      {/* <YourProgress /> */}
      <ProjectStatus {...{ projectId: -1, showDropdown: true }} />
      {data.kpiSettings.kpi_time_per_card.length > 0 && (
        <div className="top-search-result time-per-chart">
          <Card className="ant-card-no-border default-padding">
            <Row className="project-card">
              <Col span={14} className="project-card-item">
                <span className="project-card-label">Time per Chart</span>
                <span className="project-card-value">10 min</span>
              </Col>
              <Col span={10} className="project-card-item asc tr">
                <ChartGreenIcon />
              </Col>
            </Row>
            <Row className="project-card">
              <Col span={14} className="project-card-item">
                <span className="project-card-label">Average Pages</span>
                <span className="project-card-value-number">684</span>
              </Col>
              <Col span={10} className="project-card-item">
                <span className="project-card-label">Avg</span>
                <span className="project-card-value-number">15</span>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </aside>
  );
};
