import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AnnotationFileUploader,
  AnnotationProjectDetailFileList,
  PageWrapper,
} from "../containers";
import { annotationService } from "../api";
import { AnnotationProjectFile } from "../interfaces";
import { RootState, updateAnnotationFileIdToDelete, updateDeleteConfirmation } from "../store";
import { openNotificationWithIcon } from "../utils";

const Annotation = () => {
  const dispatch = useDispatch();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const { annotationFileIdToDelete } = useSelector((state: RootState) => state.annotation);

  const [loading, setLoading] = useState(false);
  const [fetchFiles, setFetchFiles] = useState(true);
  const [files, setFiles] = useState([] as AnnotationProjectFile[]);
  const [projectId, setProjectId] = useState(-1);

  const fetchProjectAnnotationFiles = useCallback(async () => {
    setLoading(true);
    const { data } = await annotationService.getProjectsList();
    if (data.data.length > 0) {
      const id = data.data[0].Id;
      setProjectId(id);
      const { data: fileRes } = await annotationService.getProjectFileList(id);
      setFiles(fileRes.data ? fileRes.data : []);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (fetchFiles) {
      fetchProjectAnnotationFiles();
      setFetchFiles(false);
    }
  }, [fetchFiles, fetchProjectAnnotationFiles]);

  useEffect(() => {
    const deleteFile = async () => {
      setLoading(true);
      try {
        await annotationService.deleteFile(annotationFileIdToDelete);
        dispatch(updateAnnotationFileIdToDelete(-1));
        await fetchProjectAnnotationFiles();
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
      } finally {
        dispatch(updateDeleteConfirmation(false));
        setLoading(false);
      }
    };
    if (isDeleteConfirmed && annotationFileIdToDelete !== -1) deleteFile();
  }, [annotationFileIdToDelete, dispatch, fetchProjectAnnotationFiles, isDeleteConfirmed]);

  return (
    <PageWrapper
      loading={projectId === -1}
      pageContainerChildren={
        <>
          <AnnotationFileUploader projectId={projectId} setFetchFiles={setFetchFiles} />
          <AnnotationProjectDetailFileList files={files} loading={loading} />
        </>
      }
    />
  );
};

export default Annotation;
