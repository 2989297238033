import { Row } from "antd";
import React from "react";
import { PatientProjects } from "../../api";
import {
  PatientPagination,
  PatientProfileHeader,
  PatientProfileInfo,
  PatientProfileProjects,
} from "./patient-insight-aside";
import { BackButton } from "../../components";
import { IPatient } from "../../interfaces";
import { PatientDetailCardProps } from ".";
import "./patient-insight-aside.scss";

interface Props {
  patientInfo: IPatient;
  patientProjects: PatientProjects[];
  selectedProject: PatientProjects;
  setSelectedProject: (item: PatientProjects) => void;
  reviewStatusMap: PatientDetailCardProps[];
  patientProjectsLoading: boolean;
}

export const PatientInsightAside = (props: Props) => {
  const {
    patientInfo,
    patientProjects,
    selectedProject,
    setSelectedProject,
    reviewStatusMap,
    patientProjectsLoading,
  } = props;
  const { patient_name, patientId, mrn, source } = patientInfo;

  return (
    <>
      <div className="flex jcsb aic">
        <BackButton />
        <PatientPagination patientId={patientId} />
      </div>
      {/* Info row */}
      <div>
        <Row justify="space-between" className="profile-info-wrapper">
          <PatientProfileHeader
            patientName={patient_name}
            projectName={selectedProject.Name}
            projectId={selectedProject.Id}
          />
          <PatientProfileInfo
            patientInfo={{
              mrn,
              source: source,
            }}
            reviewStatusMap={reviewStatusMap}
          />
          {patientProjects.length > 0 && (
            <PatientProfileProjects
              {...{
                projects: patientProjects,
                selectedProject,
                setSelectedProject,
                patientProjectsLoading,
              }}
            />
          )}
        </Row>
      </div>
    </>
  );
};
