import { Collapse, Table } from "antd";
import React from "react";
import { getStudyDescriptionColumns, TextEditor } from "../../../components";
import { studyDescriptionData } from "../../../store/data/demoData";

interface Props {
  description: string;
}

export const StudyDescription = ({ description }: Props) => {
  return (
    <div className="study-desc h-full">
      <h3 className="basic-detail-title">Study Description</h3>
      <div className="basic-detail-content">
      {/* <p className="insightdetail-desc">
        {description}
      <u>Brief Summary:</u>
        This protocol is a prospective, case-control multi-center diagnostic study to assess the
        sensitivity and specificity of blood-based screening tests for the early detection of
        multiple cancers.
      </p> */}
      <TextEditor isDisabled text={description} />
      {/* <Table
        columns={getStudyDescriptionColumns()}
        dataSource={studyDescriptionData}
        pagination={false}
        className="cmn-table"
        bordered
      /> */}
      </div>
    </div>
  );
};

const { Panel } = Collapse;

const viewMore = `
At vero eos the moment, so blinded by and equal blame belongs to those who fail in their
duty through weakness et accusamus et iusto odio dignissimos ducimus qui blanditiis
praesentium voluptatum deleniti atcorrupti quos dolores et quas molestias excepturi sint
occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
mollitia animi, id est laborum et dolorum fuga. On the other hand, we denounce with
Lorem ipsum dolor sit amet, consectetur adipiscing elit righteous indignation and
dislike men who are so beguiled and demoraliz the charms of pleasure of the moment, so
blinded by and equal blame belongs to those who fail in their duty through weakness of
will, which is the same as saying through shrinking from toil and pain. At vero eos the
moment, so blinded by and equal blame belongs to those who fail in their duty through
weakness et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
voluptatum deleniti atcorrupti quos dolores et quas molestias excepturi sint occaecati
cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi,
id est laborum et dolorum fuga praesentium voluptatum deleniti atcorrupti quos dolores
et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
`;

export const StudyDescriptionNew = () => {
  return (
    <div className="ant-card">
      <Collapse expandIconPosition="end" ghost>
        <Panel header="Description " key="1">
          <p>{viewMore}</p>
        </Panel>
      </Collapse>
    </div>
  );
};
