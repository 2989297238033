import { useCallback, useState } from "react";
import { PatientProfileService, PatientWithFilters } from "../api";

export interface FetchPatientsObj {
  search?: string;
  limit?: number;
  offset?: number;
  assigned?: boolean;
  statusId?: number;
  sortField?: string;
  sortType?: "asc" | "desc";
  projectId?: number;
}

export const useFetchPatients = () => {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([] as PatientWithFilters[]);
  const [total, setTotal] = useState(0);
  const fetchPatients = useCallback(async (obj: FetchPatientsObj) => {
    setLoading(true);
    try {
      let qs = "total=true";
      for (const [key, value] of Object.entries(obj)) {
        qs = `${qs ? `${qs}&` : ""}${key}=${value}`;
      }
      const { data } = await PatientProfileService.getPatientsListWithFilters(qs);
      setPatients([...data.data]);
      setTotal(data.pagination.totalCount ? data.pagination.totalCount : 0);
    } finally {
      setLoading(false);
    }
  }, []);

  return { patientsLoading: loading, fetchPatients, patients, totalPatients: total };
};
