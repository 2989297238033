import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addSavedSearch,
  removeSavedSearch,
  RootState,
  setRecentSearch,
  updatedRecentSearch,
} from "../../../store";
import { BookmarkIcon } from "../../../assets/icons";
import "./recent-saved-search.scss";
import { searchService } from "../../../api";
import { Spin } from "antd";
import { getLocalDateTimeStringFromISO, PAGE_URL } from "../../../utils";
import { SEARCH_HEADING } from "../search-aside";

interface Props {
  isDashboard?: boolean;
}

export const RecentSearches = ({ isDashboard }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { recent } = useSelector((state: RootState) => state.search);
  const [loading, setLoading] = useState(false);

  const handleBookmark = async (id: number) => {
    const record = recent.filter((i) => i.id === id);
    const { data } = await searchService.bookmarkSearch(id, !record[0].featured);
    dispatch(updatedRecentSearch(data.data));
    if (data.data.featured) dispatch(addSavedSearch(data.data));
    else dispatch(removeSavedSearch(data.data.id));
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data } = await searchService.getRecent();
      dispatch(setRecentSearch(data.data));
      setLoading(false);
    };
    fetch();
  }, [dispatch]);

  return (
    <div className={`recent-searches ${isDashboard ? "dashboard-topSearch" : ""}`}>
      <h3>{SEARCH_HEADING.PREVIOUS}</h3>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <ul className="scrollBar">
          {recent.map((item, i) => (
            <li key={`recent-searches-${i}`} className="flex aic jcsb searches-li">
              <span
                className="searches-title"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate({
                    pathname: `${PAGE_URL.SEARCH.LAYOUT}/${PAGE_URL.SEARCH.RESULT}`,
                    search: createSearchParams({
                      auto: "true",
                      search: item.query.join(","),
                      type: "All",
                    }).toString(),
                  })
                }
              >
                {[...item.query].slice(0, 3).join(", ")}
              </span>
              {isDashboard ? (
                <></>
              ) : (
                <>
                  {/* <span className="flex aic searches-li-right">
                    <span className="searches-date">
                      {getLocalDateTimeStringFromISO(item.updatedAt)}
                    </span>
                    <div style={{ cursor: "pointer" }} onClick={() => handleBookmark(item.id)}>
                      <BookmarkIcon />
                    </div>
                  </span> */}
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
