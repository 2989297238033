import React from "react";
import { Col } from "antd";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../utils";

interface Props {
  patientName: string;
  projectName: string;
  projectId: number;
}

export const PatientProfileHeader = (props: Props) => {
  const navigate = useNavigate();
  const { projectId, projectName, patientName } = props;

  return (
    <Col span={24}>
      <div className="profile-info-first-row">
        <p className="profile-info-name">{patientName ? patientName : "-"}</p>
        {projectName && (
          <a onClick={() => navigate(`${PAGE_URL.PROJECT_DETAIL}/${projectId}`)}>
            <h5>Project</h5>
            <span>{projectName}</span>
          </a>
        )}
      </div>
    </Col>
  );
};
