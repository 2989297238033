export * from "./ProjectDetailAside";
export * from "./ProjectDetailContainer";

export const PROJECT_DETAIL_TABS = {
  OVERVIEW: { name: "Overview", isAccessibleToClinician: true },
  PROJECT_INSIGHTS: { name: "Project Insights", isAccessibleToClinician: true },
  PROJECT_TEAM: { name: "Project Team", isAccessibleToClinician: false },
  ASSIGNED_PATIENTS: { name: "Assigned Patients", isAccessibleToClinician: true },
  PATIENT_DOCUMENTS: { name: "Patient Documents", isAccessibleToClinician: true },
  PROJECT_CONFIGURATION: { name: "Project Configurations", isAccessibleToClinician: false },
  RECOMMENDER: { name: "AI Assisted Patient Recommender", isAccessibleToClinician: true },
};
