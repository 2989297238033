import React from "react";
import { StudyDescription } from "../project-detail-components";

interface Props {
  description: string;
}

export const ProjectDetailOverview = ({ description }: Props) => {
  return <StudyDescription description={description} />;
  {
    /* <StudyDesign />
        <GroupsAndCohorts />
        <OutcomeMeasures />
        <EligibilityCriteria /> */
  }
};
