import { Card, Table } from "antd";
import React from "react";
import { ImmunizationHistory } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import {
  NoResultsFoundMessage,
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
} from "../tile-helper-components";
import { ColumnsType } from "antd/lib/table";

interface DataType {
  vaccination: string;
  date: string;
  key: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: "Vaccination",
    dataIndex: "vaccination",
    key: "vaccination",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

interface Props {
  data: ImmunizationHistory[];
  patientId: number;
}

export const ImmunizationHistoryCard = ({ data, patientId }: Props) => {
  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title="Immunization History">
      <Card className="patient-card immunization-history">
        <Table
          locale={{
            emptyText: (
              <NoResultsFoundMessage aboveEightyLength={data.length} belowEightyLength={0} />
            ),
          }}
          columns={columns}
          onRow={(record) => ({ onClick: () => handleSearchNavigate(record.vaccination) })}
          rowClassName={"cursor-pointer"}
          dataSource={data.map((a, index) => ({ ...a, key: `immunization-history-${index}` }))}
          pagination={false}
          className="cmn-table"
        />
      </Card>
    </PatientCardWrapper>
  );
};
