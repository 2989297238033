import React from "react";
import { Row, Col } from "antd";
import {
  BloodBasedBiomarkerCard,
  ConditionsCard,
  CurrentInfectiousDiseaseCard,
  DemographicsCard,
  FileListCard,
  ImmunizationHistoryCard,
  LifestyleCard,
  PatientFamilyMedicalHistory,
  PatientMedicationHistory,
  RiskFactorCard,
} from "./patient-insight-tiles";
import { IPatient } from "../../../interfaces";

interface Props {
  patientInfo: IPatient;
}

export const PatientInsightTiles = ({ patientInfo }: Props) => {
  const {
    patientId,
    conditions,
    demographics,
    family_medical_history,
    fileList,
    immunization_history,
    infectious_diseases,
    lifestyle,
    medication_history,
    threshold_confidence_score,
    biomarkers,
    risk_factor,
  } = patientInfo;

  const threshold =
    threshold_confidence_score && threshold_confidence_score.length > 0
      ? Number(threshold_confidence_score[0])
      : 80;

  return (
    <div className="project-specific m-t">
      {/* {project_specific && (
        <div className="tp-border project-specific-title">
          <p>Patient Insights</p>
        </div>
      )} */}
      <Row gutter={[14, 45]} className="profile-info-grid">
        <Col className="gutter-row" span={9}>
          {demographics && <DemographicsCard demographics={demographics} />}
        </Col>
        {/* <Col className="gutter-row" span={8}>
          {biomarkers && (
            <BloodBasedBiomarkerCard
              data={biomarkers}
              patientId={patientId}
              threshold={threshold}
            />
          )}
        </Col> */}
        {/* <Col className="gutter-row" span={8}>
          {risk_factor && (
            <RiskFactorCard riskFactors={risk_factor} patientId={patientId} threshold={threshold} />
          )}
        </Col> */}
        <Col className="gutter-row" span={15}>
          {conditions && (
            <ConditionsCard conditions={conditions} patientId={patientId} threshold={threshold} />
          )}
        </Col>
        <Col className="infectious gutter-row" span={15}>
          {infectious_diseases && (
            <CurrentInfectiousDiseaseCard
              infecitous_disease={infectious_diseases}
              patientId={patientId}
              threshold={threshold}
            />
          )}
        </Col>
        <Col className="gutter-row" span={9}>
          {fileList && <FileListCard fileList={fileList} />}
        </Col>
        <Col className="gutter-row" span={9}>
          {medication_history && (
            <PatientMedicationHistory
              patientId={patientId}
              medication_history={medication_history}
            />
          )}
        </Col>
        <Col className="gutter-row" span={15}>
          {immunization_history && (
            <ImmunizationHistoryCard data={immunization_history} patientId={patientId} />
          )}
        </Col>

        {/* <Col className="gutter-row" span={12}>
          {lifestyle && <LifestyleCard lifestyle={lifestyle} patientId={patientId} />}
        </Col> */}
        {/* <Col className="gutter-row" span={12}>
          {family_medical_history && (
            <PatientFamilyMedicalHistory
              patientId={patientId}
              family_medical_history={family_medical_history}
            />
          )}
        </Col> */}
        {/* <Col className="gutter-row" span={6}>
          {medical_history && (
            <PatientMedicalHistory medical_history={medical_history} patientId={patientId} />
          )}
        </Col> */}
      </Row>
    </div>
  );
};
