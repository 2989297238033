import { Tabs } from "antd";
import React, { useState } from "react";
import { EvidencePageResponseInfo, SearchOccurrence } from "../../api";
import { Loading, PdfViewer } from "../../components";
import { useUser } from "../../hooks";
import { DATA_ADMIN_PERMISSIONS } from "../../utils";
import {
  SearchResultOccurrences,
  EvidenceContent,
  FeedbackForm,
  Legends,
  CATEGORY_COLOR_MAPPING,
} from "./evidence-viewer-container";

const { TabPane } = Tabs;

type EnhancedViewerTabType = {
  [key: string]: string;
};

export const ENHANCED_VIEW_TABS: EnhancedViewerTabType = {
  PAGE: "AI Assisted Review",
  OCR: "Textract/OCR Ouput",
  MEDICAL_ENTITIES: "Medical Entities",
  NORMAL_ENTITIES: "Normal Entities",
  JSON: "JSON Output",
};

interface Props {
  searchKeywords: string | null;
  currPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  // setSearchKeywords: React.Dispatch<React.SetStateAction<string | null>>;
  // tileType: string | null;
  // searchResultOccurrenceLoading: boolean;
  // occurencesList: SearchOccurrence[];
  // initialSearchKeywords: string | null;

  pdfUrl: string;
  evidenceContentLoading: boolean;
  pageData: EvidencePageResponseInfo;
  pageSearchKey: string | null;
  fileId: string;
}

export const EvidenceViewerContainer = (props: Props) => {
  const {
    searchKeywords,
    currPage,
    setCurrPage,
    // setSearchKeywords,
    // tileType,
    // searchResultOccurrenceLoading,
    // initialSearchKeywords,
    // occurencesList,
    evidenceContentLoading,
    pdfUrl,
    pageData,
    fileId,
    pageSearchKey,
  } = props;

  const [selectedTab, setSelectedTab] = useState(ENHANCED_VIEW_TABS.PAGE);
  const [selectedLegends, setSelectedLegends] = useState([] as string[]);
  const { roleCode } = useUser();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <div>
      {/* {((searchKeywords && searchKeywords.length > 0) || tileType) && (
        <SearchResultOccurrences
          {...{
            currPage,
            setCurrPage,
            loading: searchResultOccurrenceLoading,
            searchKeywords,
            setSearchKeywords,
            occurencesList,
            initialSearchKeywords,
          }}
        />
      )} */}
      {DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
        <div className="tabs">
          <Tabs onChange={onChange} defaultActiveKey={selectedTab}>
            {Object.keys(ENHANCED_VIEW_TABS).map((key) => (
              <TabPane tab={ENHANCED_VIEW_TABS[key]} key={ENHANCED_VIEW_TABS[key]} />
            ))}
          </Tabs>
        </div>
      )}
      {selectedTab === ENHANCED_VIEW_TABS.PAGE && !evidenceContentLoading && (
        <Legends {...{ selectedLegends, setSelectedLegends }} />
      )}
      <div className="evidence-page">
        <EvidenceContent
          pageInfo={pageData}
          loading={evidenceContentLoading}
          selectedTab={selectedTab}
          searchKeywords={pageSearchKey || searchKeywords || ""}
          selectedLegends={selectedLegends}
        />
        {pdfUrl ? (
          <PdfViewer
            pdf={pdfUrl}
            pageInfo={{ height: 700, width: 400 }}
            displayPagination
            setCurrPage={setCurrPage}
            currPage={currPage}
          />
        ) : (
          <Loading />
        )}
      </div>
      <FeedbackForm pageNo={pageData.pageNumber} fileUUID={fileId} />
    </div>
  );
};
