import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { projectService } from "../../../api";
import { getUserWithProjectColumns } from "../../../components";
import { RootState, setUserWithProjects } from "../../../store";
import "./assign-users-to-projects.scss";

export const AssignUsersToProjects = () => {
  const dispatch = useDispatch();
  const usersWithProjectColumns = getUserWithProjectColumns(dispatch);
  const { users } = useSelector((state: RootState) => state.data);
  const { clientId } = useSelector((state: RootState) => state.user.user);
  const [loading, setLoading] = useState(false);
  const usersWithProjectData: UserWithProjectColumnDataType[] = users.usersWithProject.list.map(
    (item) => {
      const { email, id, firstName, lastName, projects } = item;
      return {
        key: id,
        name: firstName + " " + lastName,
        email,
        action: "",
        project: projects,
      };
    }
  );
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const { data } = await projectService.getUsersWithProjects(clientId);
      dispatch(setUserWithProjects(data.data));
      setLoading(false);
    };
    fetch();
  }, [clientId, dispatch]);

  return (
    <div className="clientSearch dataAdmin assign basic-detail-content">
      <div className="clientList">
        <div className="client-table assign-user">
          <Table
            dataSource={usersWithProjectData}
            columns={usersWithProjectColumns}
            loading={loading}
            pagination={false}
          ></Table>
        </div>
      </div>
    </div>
  );
};
