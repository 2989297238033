import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { clientService } from "../../api";
import { getClientColumns } from "../../components";
import {
  RootState,
  setClients,
  deleteClient,
  updateDeleteConfirmation,
  openModal,
} from "../../store";
import { MODAL_TYPE } from "../../utils";

export const SuperAdminDashboardContainer = () => {
  const dispatch = useDispatch();
  const columns = getClientColumns(dispatch);
  const { list, id: clientId } = useSelector((state: RootState) => state.data.clients);
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const [loading, setLoading] = useState(false);
  const clientsData: ClientColumnDataType[] = list.map((item) => {
    const { description, id, name } = item;
    return { key: id, name, description, action: "" };
  });

  useEffect(() => {
    const fetch = async () => {
      const { data } = await clientService.getAll();
      const clientList = data.data;
      const res = clientList.map((item) => ({
        id: item.Id,
        name: item.Name,
        description: item.Description,
      }));
      dispatch(setClients(res));
    };
    fetch();
  }, [dispatch]);

  useEffect(() => {
    const deleteClientAPI = async () => {
      setLoading(true);
      await clientService.deleteOne(clientId);
      dispatch(deleteClient(clientId));
      dispatch(updateDeleteConfirmation(false));
      setLoading(false);
    };
    if (isDeleteConfirmed && clientId !== -1) deleteClientAPI();
  }, [clientId, dispatch, isDeleteConfirmed]);

  return (
    <>
      <div className="flex jcsb aic clientSearch">
        <p className="table-heading">Clients</p>

        <Button
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_CLIENT));
          }}
        >
          Create New Client
        </Button>
        <div className="clientList">
          <Table
            className="client-table user-table"
            dataSource={clientsData}
            columns={columns}
            loading={loading}
            pagination={false}
          ></Table>
        </div>
      </div>
    </>
  );
};
