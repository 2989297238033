import { Entities, Trait } from "../../../api";

const THRESHOLD_CONFIDENCE_LEVEL_FOR_CATEGORY = 0.8;
const THRESHOLD_CONFIDENCE_LEVEL_FOR_DISEASE_TRAIT = 0.7;
const MEDICATION_CATEGORY = "MEDICATION";

interface CategoryColorMapping {
  [key: string]: string;
}
export const CATEGORY_COLOR_MAPPING: CategoryColorMapping = {
  MEDICAL_CONDITION: "#6077E1",
  MEDICATION: "#00CACD",
  TEST_TREATMENT_PROCEDURE: "#9B67C6",
  PROTECTED_HEALTH_INFORMATION: "#95CB7A",
  ANATOMY: "#E8AE3D",
};

const CATEGORY_TYPE_TO_KEYWORD_MAPPING: CategoryColorMapping = {
  DX_NAME: "DISEASE",
  PROCEDURE_NAME: "PROCEDURE",
  TEST_NAME: "TEST",
  TREATMENT_NAME: "TREATMENT",
  PHONE_OR_FAX: "CONTACT",
  SYSTEM_ORGAN_SITE: "ANATOMY",
};

const uniqueObjArray = (list: any[], key: any) => {
  return [...new Map(list.map((item) => [item[key], item])).values()];
};

const getMaxScoreTraitFromEntity = (entity: Entities): Trait => {
  if (entity.Traits.length <= 0) return { Name: "", Score: -1 };
  const trait = entity.Traits.reduce((prevValue, currValue) =>
    currValue.Score > prevValue.Score ? currValue : prevValue
  );
  return trait;
};

const getFilteredEntities = (medicalEntities: Entities[]): Entities[] => {
  const removedMedicalEntities = [] as Entities[];
  medicalEntities.forEach((item) => {
    const startOffset = item.BeginOffset;
    const endOffset = item.EndOffset;
    const itemTrait = getMaxScoreTraitFromEntity(item);
    medicalEntities.forEach((ele) => {
      if (ele.BeginOffset >= startOffset && ele.EndOffset <= endOffset && item.Id !== ele.Id) {
        const eleTrait = getMaxScoreTraitFromEntity(ele);
        if (eleTrait.Score > itemTrait.Score) removedMedicalEntities.push(item);
        else removedMedicalEntities.push(ele);
      }
    });
  });
  const entities = medicalEntities.filter((el) => removedMedicalEntities.indexOf(el) < 0);
  return entities;
};

export const getAttributesInMedicationCategory = (entity: Entities): Entities[] => {
  let res = [entity];
  if (entity.Attributes && entity.Category === MEDICATION_CATEGORY) {
    const attributesPassingThresholdScore = entity.Attributes.filter(
      (attribute) => attribute.RelationshipScore > THRESHOLD_CONFIDENCE_LEVEL_FOR_CATEGORY
    );
    res = getFilteredEntities([entity, ...attributesPassingThresholdScore]);
  }
  return res;
};

export const getEvidenceViewerMedicalEntitiesText = (
  content: string,
  medicalEntities: Entities[],
  searchKeywords: string,
  selectedLegends: string[]
) => {
  // let entities = uniqueObjArray(medicalEntities, "BeginOffset");
  // entities.reverse();
  // entities = uniqueObjArray(entities, "EndOffset");
  // entities.reverse();
  const entities = getFilteredEntities(medicalEntities);
  let offsetPositionChange = 0;
  const categoriesToMap = Object.keys(CATEGORY_COLOR_MAPPING);

  entities.forEach((entity) => {
    const traitWithMaxScore = getMaxScoreTraitFromEntity(entity);
    const isScorePassing =
      traitWithMaxScore.Score === -1
        ? entity.Score > THRESHOLD_CONFIDENCE_LEVEL_FOR_CATEGORY
        : traitWithMaxScore.Score > THRESHOLD_CONFIDENCE_LEVEL_FOR_DISEASE_TRAIT;
    if (isScorePassing && selectedLegends.includes(entity.Category)) {
      // const attibutesArrayInMedicationCategory = getAttributesInMedicationCategory(entity);
      const attibutesArrayInMedicationCategory = [entity];
      attibutesArrayInMedicationCategory.map((newEntity) => {
        const type = traitWithMaxScore.Score === -1 ? newEntity.Type : traitWithMaxScore.Name;
        // const score = traitWithMaxScore.Score === -1 ? newEntity.Score : traitWithMaxScore.Score;
        const beginOffset = newEntity.BeginOffset + offsetPositionChange;
        const color = CATEGORY_COLOR_MAPPING[newEntity.Category];
        const startHtml = `<span style='background-color:${color};color:#fff;font-size:14px;margin:5px;border-radius:4px; padding:5px;' title=${newEntity.Category}>`;
        const endHtml = `<span style='font-family:Font-Bold;font-size:12px;padding:3px;margin:0 5px;color:${color};border-radius:4px;background-color:#fff;'>${
          CATEGORY_TYPE_TO_KEYWORD_MAPPING[type] ? CATEGORY_TYPE_TO_KEYWORD_MAPPING[type] : type
        }</span></span>`;
        content = [content.slice(0, beginOffset), startHtml, content.slice(beginOffset)].join("");
        offsetPositionChange += startHtml.length;
        const endOffset = newEntity.EndOffset + offsetPositionChange;
        content = [content.slice(0, endOffset), endHtml, content.slice(endOffset)].join("");
        offsetPositionChange += endHtml.length;
      });
    }
  });

  content = `<p style="line-height: 2.2;font-family:Font-Regular;font-size:14px;">${content.replace(
    /(?:\r\n|\r|\n)/g,
    "<br/>"
  )}</p>`;

  if (searchKeywords && searchKeywords.length > 0) {
    searchKeywords.split(",").forEach((searchStr) => {
      const html = `<span style='border:3px solid #000;margin:5px;border-radius:6px; padding:4px 6px;'>${searchStr}</span>`;
      const expression = searchStr.replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&");
      content = content.replaceAll(new RegExp(expression, "gi"), html);
    });
  }
  return content;
};
