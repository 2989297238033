import React from "react";
import { IconsProps } from "./Icons";

export const UnderScoreIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 8 21"}
      width={width ? width : "8"}
      height={height ? height : "21"}
      fill={fill ? fill : "currentColor"}
    >
      <path
        d="M1.216 13.808L5.136 10.128L1.216 6.432H2.928L6.832 10.128L2.928 13.808H1.216Z"
        fill="currentcolor"
      />
      <path d="M0.97977 18.507V17.407H7.01877V18.507H0.97977Z" fill="currentcolor" />
    </svg>
  );
};
