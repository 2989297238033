import React, { useEffect, useState } from "react";
import {
  ProjectDetailOverview,
  ProjectDetailInsights,
  ProjectDetailUsers,
  ProjectDetailDocuments,
  ProjectDetailPatientRecommender,
  ProjectSpecificConfigurations,
} from "./project-detail-container";
import { AssociatedPatientsList } from "../patients-list/patients-list-container";
import { ProjectDetail } from "../../interfaces";
import { PROJECT_DETAIL_TABS } from ".";
import "./project-detail-container.scss";

interface Props {
  selectedTab: string;
  projectDetail: ProjectDetail;
  clientId: number;
}

export const ProjectDetailContainer = ({ selectedTab, projectDetail, clientId }: Props) => {
  const [component, setComponent] = useState(<></>);
  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PROJECT_DETAIL_TABS.OVERVIEW.name:
        res = <ProjectDetailOverview description={projectDetail.Description} />;
        break;
      case PROJECT_DETAIL_TABS.PROJECT_INSIGHTS.name:
        res = <ProjectDetailInsights projectId={projectDetail.Id} />;
        break;
      case PROJECT_DETAIL_TABS.PROJECT_TEAM.name:
        res = <ProjectDetailUsers projectDetail={projectDetail} />;
        break;
      case PROJECT_DETAIL_TABS.ASSIGNED_PATIENTS.name:
        res = <AssociatedPatientsList projectId={projectDetail.Id} />;
        break;
      case PROJECT_DETAIL_TABS.PATIENT_DOCUMENTS.name:
        res = <ProjectDetailDocuments projectId={projectDetail.Id} />;
        break;
      case PROJECT_DETAIL_TABS.RECOMMENDER.name:
        res = <ProjectDetailPatientRecommender projectId={projectDetail.Id} />;
        break;
      case PROJECT_DETAIL_TABS.PROJECT_CONFIGURATION.name:
        res = <ProjectSpecificConfigurations projectDetail={projectDetail} />;
        break;
    }
    setComponent(res);
  }, [clientId, projectDetail, selectedTab]);

  return <div className="tabs-content">{component}</div>;
};
