import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper } from "../containers";
import { AnnotateFileContainer } from "../containers/annotation-tool";
import "../containers/evidence-viewer/evidence-viewer-container/evidence-content.scss";

const ErrorPage = () => <div>Invalid File Id</div>;

const AnnotateFile = () => {
  const [searchParams] = useSearchParams();
  const fileId = useMemo(() => searchParams.get("fileId"), [searchParams]);

  return (
    <PageWrapper
      pageContainerChildren={fileId ? <AnnotateFileContainer fileId={fileId} /> : <ErrorPage />}
    />
  );
};

export default AnnotateFile;
