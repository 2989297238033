import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";

export const AppRoutes = () => {
  const location = useLocation();
  const displayAnnotation = process.env.REACT_APP_DISPLAY_ANNOTATION === "true";

  return (
    <Routes location={location}>
      <Route element={<PrivateLayout />}>
        <Route path={PAGE_URL.DASHBOARD} element={<Pages.Dashboard />} />
        <Route path={PAGE_URL.PATIENTS_LIST} element={<Pages.PatientsList />} />
        <Route
          path={`${PAGE_URL.PATIENT_PROFILE}/:patientId`}
          element={<Pages.PatientInsights />}
        />
        <Route path={PAGE_URL.SEARCH.LAYOUT} element={<Pages.Search.Layout />}>
          <Route path={PAGE_URL.SEARCH.HOME} element={<Pages.Search.Home />} />
          <Route path={PAGE_URL.SEARCH.RESULT} element={<Pages.Search.Result />} />
        </Route>
        <Route path={PAGE_URL.EVIDENCE_VIEWER} element={<Pages.EvidenceViewer />} />
        <Route path={PAGE_URL.INSIGHT_DETAIL} element={<Pages.InsightDetail />} />
        <Route path={`${PAGE_URL.CLIENT_DETAIL}/:clientId`} element={<Pages.ClientDetail />} />
        <Route path={PAGE_URL.SETTINGS} element={<Pages.SettingsPage />} />
        <Route path={`${PAGE_URL.PROJECT_DETAIL}/:projectId`} element={<Pages.ProjectDetail />} />
        <Route path={`${PAGE_URL.ROLE_MAPPING}`} element={<Pages.RoleMapping />} />
        <Route
          path={`${PAGE_URL.CONFIGURATIONS}`}
          element={<Pages.EnterpsieAdminConfigurations />}
        />
        {displayAnnotation && (
          <Route path={PAGE_URL.ANNOTATION} element={<Pages.AnnotationProjectDetail />} />
        )}
        {displayAnnotation && (
          <Route path={`${PAGE_URL.ANNOTATE_FILE}/:fileName`} element={<Pages.AnnotateFile />} />
        )}
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
        <Route path={PAGE_URL.RESET_PASSWORD} element={<Pages.ResetPassword.Form />} />
        <Route path={PAGE_URL.RESET_PASSWORD_SUCCESS} element={<Pages.ResetPassword.Success />} />
        <Route path={PAGE_URL.CREATE_PASSWORD} element={<Pages.CreatePassword.Form />} />
        <Route path={PAGE_URL.CREATE_PASSWORD_SUCCESS} element={<Pages.CreatePassword.Success />} />
      </Route>
    </Routes>
  );
};
