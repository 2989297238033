import { Client, UserWithProject } from "./types";

// export const userWithProjectDemoData: UserWithProjectColumnDataType[] = [
//   {
//     key: "1",
//     name: "Jimmi Falcom",
//     email: "jimmi.falcon@gmail.com",
//     action: "New York No. 1 Lake Park",
//     project: ["FREENOME Multiomics Study"],
//   },
//   {
//     key: "2",
//     name: "Mathew Lauren",
//     email: "mlauren@gmail.com",
//     action: "London No. 1 Lake Park",
//     project: ["Pfizer-BioNTech COVID-19 BNT162b2"],
//   },
//   {
//     key: "3",
//     name: "Meyda Smith",
//     email: "msmith@gmail.com",
//     action: "Sidney No. 1 Lake Park",
//     project: ["Pfizer-BioNTech COVID-19 BNT162b2"],
//   },
//   {
//     key: "4",
//     name: "Rupper Argon",
//     email: "r.argon@gmail.com",
//     action: "Sidney No. 1 Lake Park",
//     project: ["FREENOME Multiomics Study"],
//   },
//   {
//     key: "5",
//     name: "Jimmi Falcom",
//     email: "jimmi.falcon@gmail.com",
//     action: "Sidney No. 1 Lake Park",
//     project: ["FREENOME Multiomics Study"],
//   },
// ];

export const clientDemoData: ClientColumnDataType[] = [
  {
    key: "1",
    name: "Aagami",
    description: "Lorem Ipsum",
    action: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Abiogenesis Clinpharm",
    description: "Lorem Ipsum",
    action: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Accelsiors",
    description: "Lorem Ipsum",
    action: "Sidney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Accutest Global",
    description: "Lorem Ipsum",
    action: "Sidney No. 1 Lake Park",
  },
  {
    key: "5",
    name: "Catalyst Clinical Services",
    description: "Lorem Ipsum",
    action: "Sidney No. 1 Lake Park",
  },
];

// export const projectDemoData: ProjectColumnDataType[] = [
//   {
//     key: "1",
//     project: "FREENOME Multiomics Study",
//     description: "Lorem Ipsum",
//     sponsor: "Lorem",
//     details: "Lorem",
//   },
//   {
//     key: "2",
//     project: "Pfizer-BioNTech COVID-19 BNT162b2",
//     description: "Lorem Ipsum",
//     sponsor: "Lorem",
//     details: "Lorem",
//   },
//   {
//     key: "3",
//     project: "Cardiovascular ",
//     description: "Lorem Ipsum",
//     sponsor: "Lorem",
//     details: "Lorem",
//   },
//   {
//     key: "4",
//     project: "Bayer C-19 Anticoag Study",
//     description: "Lorem Ipsum",
//     sponsor: "Lorem",
//     details: "Lorem",
//   },
// ];

export const userDemoData: UserColumnDataType[] = [
  {
    key: "1",
    name: "Jimmi Falcom",
    email: "jimmi.falcon@gmail.com",
    action: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Mathew Lauren",
    email: "mlauren@gmail.com",
    action: "London No. 1 Lake Park",
  },
  {
    key: "3",
    name: "Meyda Smith",
    email: "msmith@gmail.com",
    action: "Sidney No. 1 Lake Park",
  },
  {
    key: "4",
    name: "Rupper Argon",
    email: "r.argon@gmail.com",
    action: "Sidney No. 1 Lake Park",
  },
  {
    key: "5",
    name: "Jimmi Falcom",
    email: "jimmi.falcon@gmail.com",
    action: "Sidney No. 1 Lake Park",
  },
];

export const groupCohortsData: GroupCohortsDataType[] = [
  {
    key: "1",
    groupscohorts: "Collect Subject Data Collect Blood Specimen",
    interventiontreatment:
      "Participants who provide informed consent, meet the eligibility criteria and provide a blood sample for this study will be enrolled.",
  },
  {
    key: "2",
    groupscohorts: "Data Collect Blood Specimen One Year Follow Up On Cancer Status",
    interventiontreatment:
      "Participants who provide informed consent, meet the eligibility criteria and provide a blood sample for this study will be enrolled.",
  },
];

export const studyDescriptionData: StudyDescDataType[] = [
  {
    key: "1",
    conditiondisease: "Newly-diagnosed Cancers",
    interventiontreatment: "Diagnostic Test: Freenome Test",
    // phases: "Not Applicable",
  },
  {
    key: "2",
    conditiondisease: "Non-cancer Controls",
    interventiontreatment: " ",
    // phases: "Not Applicable",
  },
];

// export const documentsListData: DocumentsColumnDataType[] = [
//   {
//     key: "1",
//     name: "Frank_Damas_EHR_Combined.pdf fileid001122233",
//     // category: "Discharge summary",
//     // document: "stage 01",
//     // uploaded: "Thu, July 21, 2022",
//     // uploadby: "admin@admin.com",
//     completedOn: "2022-08-13 05:20:17.748974",
//     createdOn: "2022-08-13 05:20:17.748974",
//     status: "SUCCEEDED",
//     action: " ",
//   },
//   {
//     key: "2",
//     name: "filename.pdf fileid001122233",
//     // category: "Form",
//     // document: "stage 02",
//     // uploaded: "Thu, July 21, 2022",
//     // uploadby: "admin@admin.com",
//     completedOn: "2022-08-13 05:20:17.748974",
//     createdOn: "2022-08-13 05:20:17.748974",
//     status: "ERROR",
//     action: " ",
//   },
//   {
//     key: "3",
//     name: "filename.pdf fileid001122233",
//     // category: "Reports",
//     // document: "stage 03",
//     // uploaded: "Thu, July 21, 2022",
//     // uploadby: "admin@admin.com",
//     completedOn: "2022-08-13 05:20:17.748974",
//     createdOn: "2022-08-13 05:20:17.748974",
//     status: "INFORMATION EXTRACTION STARTED",
//     action: " ",
//   },
//   {
//     key: "4",
//     name: "filename.pdf fileid001122233",
//     // category: "Reports",
//     // document: "stage 04",
//     // uploaded: "Thu, July 21, 2022",
//     // uploadby: "admin@admin.com",
//     completedOn: "2022-08-13 05:20:17.748974",
//     createdOn: "2022-08-13 05:20:17.748974",
//     status: "IN_PROGRESS",
//     action: " ",
//   },
// ];

export const clientInitialData: Client[] = clientDemoData.map((item) => {
  const { description, key, name } = item;
  return { id: Number(key), name, description };
});

// export const projectInitialData: Project[] = projectDemoData.map((item) => {
//   const { description, key, project, sponsor } = item;
//   return { id: key.toString(), project, description, sponsor };
// });

// export const userInitialData: User[] = userDemoData.map((item) => {
//   const { email, key, name } = item;
//   const nameSplit = name.split(" ");
//   return {
//     id: Number(key.toString()),
//     firstName: nameSplit[0],
//     lastName: nameSplit[nameSplit.length - 1],
//     email,
//     client: 0,
//     roleId: 2,
//     roleName: "Data Admion",
//     status: false,
//   };
// });

// export const userWithProjectInitialData: UserWithProject[] = userWithProjectDemoData.map((item) => {
//   const { email, key, name, project } = item;
//   const nameSplit = name.split(" ");
//   return {
//     id: Number(key.toString()),
//     firstName: nameSplit[0],
//     lastName: nameSplit[nameSplit.length - 1],
//     email,
//     client: 0,
//     roleId: 2,
//     roleName: "Data Admion",
//     project: project.join(" "),
//     status: false,
//   };
// });
