import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MODAL_TYPE, openNotificationWithIcon } from "../../utils";
import {
  deleteUserRole,
  openModal,
  RootState,
  setUserRoles,
  updateDeleteConfirmation,
} from "../../store";
import { getRoleMappingColumns } from "../../components";
import { rolesService } from "../../api";
import "./roleMapping.scss";

export const RoleMappingContainer = () => {
  const dispatch = useDispatch();
  const columns = getRoleMappingColumns(dispatch);
  const [loading, setLoading] = useState(false);
  const userRoles = useSelector((state: RootState) => state.user.roles);
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const userRolesData: RoleMappingColumnDataType[] =
    userRoles && userRoles.list
      ? userRoles.list.map((item) => {
          const { Id, VisualName, Name, IsDefault } = item;
          return {
            key: Id,
            autonomizeRole: Name,
            role: VisualName,
            action: "",
            isDefault: IsDefault,
          };
        })
      : [];

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      const { data } = await rolesService.getAll();
      dispatch(setUserRoles(data.data));
      setLoading(false);
    };
    fetchRoles();
    return () => {
      dispatch(setUserRoles([]));
    };
  }, [dispatch]);

  useEffect(() => {
    const deleteRole = async () => {
      setLoading(true);
      try {
        await rolesService.deleteRole(userRoles.id);
        dispatch(deleteUserRole(userRoles.id));
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
      } finally {
        dispatch(updateDeleteConfirmation(false));
        setLoading(false);
      }
    };
    if (isDeleteConfirmed && userRoles.id !== -1) deleteRole();
  }, [userRoles.id, dispatch, isDeleteConfirmed]);

  return (
    <>
      <div className="flex jcsb aic">
        <p className="table-heading">Roles</p>
        <Button
          // type="primary"
          // icon={<PlusOutlined />}
          className="outline"
          onClick={() => {
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER_ROLE));
          }}
        >
          Add New Role
        </Button>
      </div>
      {/* <div className="role-table"> */}
      <Table
        className="user-table"
        dataSource={userRolesData}
        columns={columns}
        loading={loading}
        pagination={false}
      ></Table>
      {/* </div> */}
    </>
  );
};
