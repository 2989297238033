import { Card } from "antd";
import React, { useCallback } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PdfIcon } from "../../../../assets/icons/PdfIcon";
import { PatientFile } from "../../../../interfaces";
import { PAGE_URL } from "../../../../utils";
import { PatientCardWrapper } from "../tile-helper-components";

interface Props {
  fileList: PatientFile[];
}

export const FileListCard = ({ fileList }: Props) => {
  const navigate = useNavigate();

  const handlePdfClick = useCallback(
    (file: PatientFile) => {
      navigate({
        pathname: PAGE_URL.EVIDENCE_VIEWER,
        search: createSearchParams({
          fileName: file.name,
          fileId: file.UUID,
        }).toString(),
      });
    },
    [navigate]
  );

  return (
    <PatientCardWrapper title={"Source Charts/Data Sources"}>
      <Card className="orange patient-card">
        {fileList.map((file: PatientFile, index: number) => (
          <div key={`file-list-item-${index}`} className="listitem">
            <h4
              className="reviewlist"
              onClick={() => handlePdfClick(file)}
              style={{ cursor: "pointer" }}
            >
              {/* <PdfIcon className="pdf-icon" />  */}
              {file.name}
            </h4>
          </div>
        ))}
      </Card>
    </PatientCardWrapper>
  );
};
