import { ColumnsType } from "antd/lib/table";
import { Button, Switch } from "antd";
import React, { Dispatch } from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { InfoCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { DeleteIcon, EditIcon } from "../assets/icons";
import {
  disableEnableUser,
  openModal,
  setClientEditDelete,
  setProjectDocumentDetail,
  setProjectEditDelete,
  setUserEditDelete,
  setUserRoleEdit,
  setUserWithProjectsEdit,
  updateProject,
  updateProjectUser,
} from "../store";
import {
  DATA_ADMIN_PERMISSIONS,
  DOCUMENT_STATUS,
  MODAL_TYPE,
  openNotificationWithIcon,
  PAGE_URL,
  USER_ROLES_CODE,
  getLocalDateTimeStringFromISO,
} from "../utils";
import { projectService, usersService } from "../api";

export const getUserWithProjectColumns = (
  dispatch: Dispatch<AnyAction>
): ColumnsType<UserWithProjectColumnDataType> => [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Project",
    key: "project",
    dataIndex: "project",
    render: (text, record) => (
      <div className="multi-project flex flex-column">
        {record.project.map((item, index) => (
          <p className="flex gp-10 aic" key={index}>
            <CheckOutlined />
            {item.project_name}
          </p>
        ))}
      </div>
    ),
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (value, record) => (
      <div className="editDelete jcc flex">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setUserWithProjectsEdit(Number(record.key)));
            dispatch(openModal(MODAL_TYPE.ASSIGN_PROJECT));
          }}
        >
          Assign to Project
        </a>
      </div>
    ),
  },
];

export const getClientColumns = (
  dispatch: Dispatch<AnyAction>
): ColumnsType<ClientColumnDataType> => [
  {
    title: "Client Name",
    key: "name",
    dataIndex: "name",
    render: (text, value) => <Link to={`${PAGE_URL.CLIENT_DETAIL}/${value.key}`}>{text}</Link>,
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, value) => (
      <div className="editDelete gp jcc flex">
        <EditIcon
          onClick={() => {
            dispatch(setClientEditDelete({ id: Number(value.key), isEdit: true }));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_CLIENT));
          }}
        />
        <DeleteIcon
          onClick={() => {
            dispatch(setClientEditDelete({ id: Number(value.key), isEdit: false }));
            dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
          }}
        />
      </div>
    ),
  },
];

export const getProjectColumns = (
  dispatch: Dispatch<AnyAction>,
  role: string
): ColumnsType<ProjectColumnDataType> => [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  // {
  //   title: "Description",
  //   key: "description",
  //   dataIndex: "description",
  // },
  // {
  //   title: "Sponsor",
  //   key: "sponsor",
  //   dataIndex: "sponsor",
  // },
  {
    title: "Action",
    key: "details",
    dataIndex: "details",
    render: (text, value) => {
      const onChange = async (status: boolean) => {
        try {
          const { data } = await projectService.disableEnable(
            value.clientId,
            status,
            Number(value.key)
          );
          dispatch(updateProject(data.data));
        } catch (err: any) {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      };

      return (
        <div className="editDelete jcc flex gp">
          <Link to={`${PAGE_URL.PROJECT_DETAIL}/${value.key}`}>View More</Link>
          {DATA_ADMIN_PERMISSIONS.includes(role) && (
            <>
              <EditIcon
                className={value.status ? "cursor-pointer" : "cursor-disable"}
                onClick={() => {
                  if (value.status) {
                    dispatch(setProjectEditDelete({ id: Number(value.key), isEdit: true }));
                    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
                  }
                }}
              />
              <Switch size="small" checked={value.status} onChange={onChange} />
              {/* <DeleteIcon
              onClick={() => {
                dispatch(setProjectEditDelete({ id: Number(value.key), isEdit: false }));
                dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
              }}
            /> */}
            </>
          )}
        </div>
      );
    },
  },
];

// const [disabled, setDisabled] = useState(true);
// const toggle = () => {
//   setDisabled(!disabled);
// };

export const getUserColumns = (
  dispatch: Dispatch<AnyAction>,
  isUsersList = true
): ColumnsType<UserColumnDataType> => [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Role",
    key: "role",
    dataIndex: "role",
  },
  // roleId === USER_ROLES_CODE.ENTERPRISE_ADMIN
  //   ? {}
  //   : {
  //       title: "Role",
  //       key: "role",
  //       dataIndex: "role",
  //     },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, value) => {
      const onChange = async (status: boolean) => {
        if (!isUsersList && value.relationId) {
          const { data } = await projectService.disableEnableProjectUsers(value.relationId, status);
          dispatch(updateProjectUser(data.data));
        } else {
          const { data } = await usersService.disableEnable(status, Number(value.key));
          dispatch(disableEnableUser({ userId: data.data.id, status: data.data.status }));
        }
      };
      return (
        <div className="editDelete aic jcc gp flex">
          <Switch size="small" checked={value.status} onChange={onChange} />
          {isUsersList && (
            <>
              <EditIcon
                className={value.status ? "cursor-pointer" : "cursor-disable"}
                onClick={() => {
                  if (value.status) {
                    dispatch(setUserEditDelete({ id: Number(value.key), isEdit: true }));
                    dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER));
                  }
                }}
              />
              <p
                className={value.status ? "cursor-pointer" : "cursor-disable"}
                onClick={() => {
                  if (value.status) {
                    dispatch(setUserEditDelete({ id: Number(value.key), isEdit: true }));
                    dispatch(openModal(MODAL_TYPE.RESET_USER_PASSWORD));
                  }
                }}
              >
                Reset Password
              </p>
            </>
          )}
        </div>
      );
    },
  },
];

export const getGroupCohortsColumns = (): ColumnsType<GroupCohortsDataType> => [
  {
    title: "Groups / Cohorts",
    dataIndex: "groupscohorts",
    // render: () => (
    //   <div className="cohorts-columns">
    //     <p>Cancer Group</p>
    //     <ul>
    //       <li>Collect Subject Data</li>
    //     </ul>
    //   </div>
    // ),
    key: "groupscohorts",
    width: "50%",
  },
  {
    title: "Intervention/Treatment",
    dataIndex: "interventiontreatment",
    key: "interventiontreatment",
    width: "50%",
  },
];

export const getStudyDescriptionColumns = (): ColumnsType<StudyDescDataType> => [
  {
    title: "Condition or Disease",
    dataIndex: "conditiondisease",
    key: "conditiondisease",
  },
  {
    title: "Intervention/Treatment",
    dataIndex: "interventiontreatment",
    key: "interventiontreatment",
  },
  // {
  //   title: "Phases",
  //   dataIndex: "phases",
  //   key: "phases",
  // },
];

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.COMPLETED:
      return "#44C658";
    case DOCUMENT_STATUS.ERROR:
      return "red";
    default:
      return "#475569";
  }
};

export const getDocumentListColumns = (
  dispatch: Dispatch<AnyAction>,
  navigate: NavigateFunction
): ColumnsType<DocumentsColumnDataType> => [
  {
    title: "Filename & ID",
    key: "name",
    dataIndex: "name",
    render: (item, record) => (
      <p
        className="cc flex cursor-pointer"
        onClick={() =>
          record.status === DOCUMENT_STATUS.COMPLETED &&
          navigate(`${PAGE_URL.EVIDENCE_VIEWER}?fileId=${record.key}`)
        }
      >
        {item}
        {/* <ReloadOutlined /> */}
      </p>
    ),
  },
  // {
  //   title: "Category",
  //   key: "category",
  //   dataIndex: "category",
  // },
  // {
  //   title: "Document Stage",
  //   key: "document",
  //   dataIndex: "document",
  // },
  {
    title: "Uploaded on ",
    key: "createdOn",
    dataIndex: "createdOn",
    render: (item) => <p>{item ? getLocalDateTimeStringFromISO(item) : ""}</p>,
  },
  {
    title: "Completed on ",
    key: "completedOn",
    dataIndex: "completedOn",
    render: (item) => <p>{item ? getLocalDateTimeStringFromISO(item) : ""}</p>,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text, value) => (
      <div className="status" style={{ color: getClass(value.status) }}>
        <p style={{ color: getClass(value.status) }}>{value.status}</p>{" "}
      </div>
    ),
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (text, record: DocumentsColumnDataType) => (
      <div
        className="editDelete jcc flex cursor-pointer"
        onClick={() => {
          const { completedOn, createdOn, name, status, statusList, key } = record;
          dispatch(
            setProjectDocumentDetail({
              bucketName: "",
              documentCompletedOn: completedOn,
              documentCreatedOn: createdOn,
              objectName: name,
              documentId: key.toString(),
              documentStatus: status,
              statusList: statusList,
            })
          );
          dispatch(openModal(MODAL_TYPE.PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS));
        }}
      >
        <a>View More</a>
      </div>
    ),
  },
];

export const getRoleMappingColumns = (
  dispatch: Dispatch<AnyAction>
): ColumnsType<RoleMappingColumnDataType> => [
  {
    title: "New Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Autonomize Role",
    dataIndex: "autonomizeRole",
    key: "autonomizeRole",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (value, record) => (
      <div className="editDelete jcc flex gp">
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            dispatch(setUserRoleEdit(Number(record.key)));
            dispatch(openModal(MODAL_TYPE.ADD_EDIT_USER_ROLE));
          }}
        />
        <DeleteIcon
          className={record.isDefault ? "cursor-disable" : "cursor-pointer"}
          onClick={() => {
            if (!record.isDefault) {
              dispatch(setUserRoleEdit(Number(record.key)));
              dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
            }
          }}
        />
      </div>
    ),
  },
];
