import React from "react";
import "../patientprofile.scss";

interface Props {
  children: React.ReactNode;
  title: string;
}

export const PatientCardWrapper = ({ children, title }: Props) => {
  return (
    <div className="patient-card-wrapper">
      <h3>{title}</h3>
      {children}
    </div>
  );
};
