import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table } from "antd";
import { scoreToPercentage } from "../../../../utils";
import { BaseEntity } from "../../../../api";
import "../patientprofile.scss";
import {
  CONFIDENCE_SCORE_INFO,
  EVIDENCE_VIEWER_URL,
  PatientCardWrapper,
  NoResultsFoundMessage,
  ViewMoreResults,
} from "../tile-helper-components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";

interface DataType {
  key: string;
  riskFactor: string;
  score: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Risk Factor",
    dataIndex: "riskFactor",
    key: "riskFactor",
  },
  {
    title: "Confidence Score",
    filters: [],
    filterIcon: () => <InfoCircleOutlined title={CONFIDENCE_SCORE_INFO} />,
    dataIndex: "score",
    key: "score",
    render: (value) => <div>{value}</div>,
  },
];

interface Props {
  riskFactors: BaseEntity[];
  patientId: number;
  threshold: number;
}

export const RiskFactorCard = ({ riskFactors, patientId, threshold }: Props) => {
  const sorted = riskFactors.sort((a, b) => b.Score - a.Score);
  const aboveEighty = sorted.filter((a) => a.Score >= threshold / 100);
  const belowEighty = sorted.filter((a) => a.Score < threshold / 100);
  const navigate = useNavigate();

  const handleSearchNavigate = (str: string) => {
    navigate(`${EVIDENCE_VIEWER_URL}&search=${str}&patient_id=${patientId}`);
  };

  return (
    <PatientCardWrapper title={"Risk Factors"}>
      <Card className={"cyan patient-card"}>
        <Table
          columns={columns}
          locale={{
            emptyText: (
              <NoResultsFoundMessage
                aboveEightyLength={aboveEighty.length}
                belowEightyLength={belowEighty.length}
                threshold={threshold}
              />
            ),
          }}
          dataSource={
            aboveEighty
              ? aboveEighty.map((item, index) => ({
                  riskFactor: item.Text,
                  score: scoreToPercentage(item.Score),
                  key: `risk-factor-item-${index}`,
                }))
              : []
          }
          pagination={false}
          className="two-row"
          rowClassName={"cursor-pointer"}
          onRow={(record) => ({ onClick: () => handleSearchNavigate(record.riskFactor) })}
        />
        <ViewMoreResults
          searchParanms={{
            search: [...new Set(belowEighty.map((a) => a.Text))].join(","),
            patient_id: patientId,
          }}
          belowEightyLength={belowEighty.length}
          threshold={threshold}
        />
      </Card>
    </PatientCardWrapper>
  );
};
