import React from "react";
import { Collapse } from "antd";
import { PreviousSearches, SavedSearches, SEARCH_HEADING } from "./search-aside";
import "./search-aside/previous-saved-search.scss";
import {PaginationRightArrowIcon, PaginationLeftArrowIcon} from "../../assets/icons";

const { Panel } = Collapse;

export const SearchAside = () => {
  return (
    <div className="searches flex flex-col customize-accordion">
      <Collapse
        accordion
        defaultActiveKey={[SEARCH_HEADING.SAVED]}
        expandIconPosition="end"
        expandIcon={(e) => (e.isActive ? <PaginationLeftArrowIcon/> : <PaginationRightArrowIcon  />)}
      >
        <Panel header={SEARCH_HEADING.PREVIOUS} key={SEARCH_HEADING.PREVIOUS}>
          <PreviousSearches />
        </Panel>
        <Panel header={SEARCH_HEADING.SAVED} key={SEARCH_HEADING.SAVED}>
          <SavedSearches />
        </Panel>
      </Collapse>
    </div>
  );
};
