import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Loading } from "../../components";
import { PageAsideLeftWrapper } from "./PageAsideLeftWrapper";
import { PageContainerWrapper } from "./PageContainerWrapper";

interface Props {
  loading?: boolean;
  pageLeftAsideChildren?: JSX.Element;
  pageContainerChildren: JSX.Element;
}

export const PageWrapper = ({ loading, pageContainerChildren, pageLeftAsideChildren }: Props) => {
  const noGrid = !pageLeftAsideChildren || loading;

  return (
    <Content className={noGrid ? "no-grid" : ""}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {pageLeftAsideChildren && (
            <PageAsideLeftWrapper>{pageLeftAsideChildren}</PageAsideLeftWrapper>
          )}
          <PageContainerWrapper>{pageContainerChildren}</PageContainerWrapper>
        </>
      )}
    </Content>
  );
};
