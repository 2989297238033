import React from "react";
import { Select } from "antd";
import { DropdownOption } from "../interfaces";

interface Props {
  defaultValue?: string;
  value?: string;
  options: DropdownOption[];
  loading: boolean;
  onSelect: (value: string, option: DropdownOption) => void;
  notFoundContent?: React.ReactNode;
}

export const Dropdown = (props: Props) => {
  return (
    <Select
      style={{ width: 210 }}
      className="ai-select"
      // dropdownClassName="profile-info-filter"
      dropdownMatchSelectWidth={true}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    ></Select>
  );
};
