import React from "react";
import { Col, Card, Progress } from "antd";
import "./top-searches.scss";
import { RecentSearches } from "../../search/recent-searches/RecentSearches";
import { SavedSearches } from "../../search/saved-searches/SavedSearches";

export const TopSearches = () => {
  return (
    <div className="ant-card topSearch">
      <RecentSearches isDashboard />
      <SavedSearches isDashboard />
    </div>
  );
};
