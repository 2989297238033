import React, { useEffect, useState } from "react";
import { RootState, setProjectDetail } from "../store";
import { useDispatch, useSelector } from "react-redux";
import {
  PageWrapper,
  ProjectDetailAside,
  ProjectDetailContainer,
  PROJECT_DETAIL_TABS,
} from "../containers";
import { projectService } from "../api";
//import "./projectDetail.scss";
//import "./insightdetail.scss";
import "../assets/sass/_tabs.scss";
import { useParams } from "react-router-dom";
import { useUser } from "../hooks";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const { clientId, roleCode } = useUser();
  const [selectedTab, setSelectedTab] = useState(PROJECT_DETAIL_TABS.OVERVIEW.name);
  const projectDetail = useSelector((state: RootState) => state.project).detail.detail;
  const [loading, setLoading] = useState(false);

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        setLoading(true);
        const { data } = await projectService.getOne(clientId, projectId);
        dispatch(setProjectDetail(data.data));
        setLoading(false);
      }
    };
    fetchProject();
  }, [clientId, dispatch, projectId]);

  return (
    <PageWrapper
      loading={loading}
      pageLeftAsideChildren={
        <ProjectDetailAside
          {...{ selectedTab, onTabChange, roleCode, projectName: projectDetail.Name }}
        />
      }
      pageContainerChildren={
        <ProjectDetailContainer {...{ projectDetail, selectedTab, clientId }} />
      }
    />
  );
};

export default ProjectDetail;
