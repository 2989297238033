import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Tag, Progress } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectService } from "../../../api";
import { ProjectDetail, ProjectMeta } from "../../../interfaces";
import {
  openModal,
  removeProjectConfiguration,
  RootState,
  setProjectConfiguration,
  setProjectDetail,
  updateDeleteConfirmation,
} from "../../../store";
import { isEmptyObject, MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import "./project-specific-configurations.scss";
import { DeleteIcon, EditIcon } from "../../../assets/icons";

const NoProjectConfigurations = () => {
  const dispatch = useDispatch();
  return (
    <div className="project-specific-config-add basic-detail-content flex-center">
      <div>
        <h3>Add New Insights Field</h3>
        <p>Currently no data added in configuration please add by clicking below button</p>
        <Button
          className="outline"
          type="primary"
          onClick={() => dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION))}
        >
          Add New Insights Field
        </Button>
      </div>
    </div>
  );
};

interface ProjectConfigurationSectionProps {
  configuration: ProjectMeta;
  projectDetail: ProjectDetail;
}
const ProjectConfigurationSection = ({
  configuration,
  projectDetail,
}: ProjectConfigurationSectionProps) => {
  const { field_content, field_name: title } = configuration;
  const { columns, details, threshold_confidence_score } = field_content;
  const dispatch = useDispatch();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const editDeleteConfigObj = useSelector((state: RootState) => state.project.projectConfiguration);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleOnEditClick = useCallback(() => {
    dispatch(setProjectConfiguration(configuration));
    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION));
  }, [configuration, dispatch]);

  const handleOnDeleteClick = useCallback(() => {
    dispatch(setProjectConfiguration(configuration));
    dispatch(openModal(MODAL_TYPE.DELETE_CONFIRMATION));
  }, [configuration, dispatch]);

  useEffect(() => {
    const deleteConfig = async () => {
      setDeleteLoading(true);
      try {
        const { data } = await projectService.deleteProjectMeta(
          projectDetail.Id,
          editDeleteConfigObj.field_key
        );
        dispatch(setProjectDetail({ ...projectDetail, meta: data.data.meta }));
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
      } finally {
        dispatch(updateDeleteConfirmation(false));
        dispatch(removeProjectConfiguration());
        setDeleteLoading(false);
      }
    };
    if (
      isDeleteConfirmed &&
      !isEmptyObject(editDeleteConfigObj) &&
      editDeleteConfigObj.field_key === configuration.field_key
    )
      deleteConfig();
  }, [editDeleteConfigObj, dispatch, isDeleteConfirmed, projectDetail, configuration.field_key]);

  return (
    <div className="project-specific-config-edit-container basic-detail-content">
      <div className="container-title flex gp aic jcsb ">
        <h4>{title}</h4>
        <div className="flex gp">
          <Button
            // type="primary"
            className="icon-btn"
            icon={<EditIcon />}
            onClick={handleOnEditClick}
            disabled={deleteLoading}
          ></Button>
          <Button
            // type="primary"
            className="icon-btn"
            icon={<DeleteIcon />}
            onClick={handleOnDeleteClick}
            loading={deleteLoading}
          ></Button>
        </div>
      </div>
      <div className="container-content customize-ant-tag">
        <div className="detail-group">
          <h3>Field Details</h3>
          <div>
            {details.map((item, index) => (
              <Tag key={`field-content-detail-${index}`}>{item}</Tag>
            ))}
          </div>
        </div>
        <div className="flex gp-40 w-full">
          <div className="columns-group">
            <h3>Field display columns</h3>
            <div>
              {columns.length === 0 && <p>No columns added</p>}
              {columns.map((item, index) => (
                <Tag key={`field-content-columns-${index}`}>{item}</Tag>
              ))}
            </div>
          </div>
          <div className="probability-group">
            <h3>Field confidence score</h3>
            <Progress
              strokeLinecap="butt"
              type="circle"
              strokeColor="#3b70cd"
              width={45}
              strokeWidth={8}
              percent={threshold_confidence_score}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface ProjectSpecificConfigurationsProps {
  projectDetail: ProjectDetail;
}

export const ProjectSpecificConfigurations = ({
  projectDetail,
}: ProjectSpecificConfigurationsProps) => {
  const dispatch = useDispatch();

  return (
    <div className="project-specific-config">
      {projectDetail.meta?.length > 0 ? (
        <div className="project-specific-config-edit">
          <div className="tr">
            <Button
              className="outline"
              type="primary"
              onClick={() => dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT_CONFIGURATION))}
            >
              Add New Insights Field
            </Button>
          </div>
          <div className="project-configuration-container">
            {projectDetail.meta?.map((item, index) => (
              <ProjectConfigurationSection
                key={`project-configuration-${index}`}
                configuration={item}
                projectDetail={projectDetail}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoProjectConfigurations />
      )}
    </div>
  );
};
