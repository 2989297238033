import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { clientService } from "../api";
import { ClientDetailAside, ClientDetailContainer, PageWrapper } from "../containers";
import { useFetchUserList } from "../hooks";
import { Client } from "../store/data/types";
import "./dashboard/superAdmin.scss";

const ClientDetail = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { fetchUsers } = useFetchUserList();
  const [loading, setLoading] = useState(true);
  const [clientDetail, setClientDetail] = useState({} as Client);

  useEffect(() => {
    const fetch = async () => {
      if (clientId) {
        setLoading(true);
        const { data: clientDetailResponse } = await clientService.getOne(clientId);
        const { Id: id, Name: name, Description: description } = clientDetailResponse.data;
        setClientDetail({ id, name, description });
        await fetchUsers(id);
        setLoading(false);
      }
    };
    fetch();
  }, [clientId, dispatch, fetchUsers]);

  return (
    <PageWrapper
      loading={loading}
      pageLeftAsideChildren={<ClientDetailAside client={clientDetail} />}
      pageContainerChildren={<ClientDetailContainer clientId={clientDetail.id} />}
    />
  );
};

export default ClientDetail;
