import React from "react";
import "../patientprofile.scss";

interface Props {
  patientDetailAttribute: {
    value: string;
    key: string;
    keyTitle?: string;
    icd10code?: string;
    isKeyHighlighted?: boolean;
  };
  title?: string;
}

export const PatientDetailCardInfo = ({ patientDetailAttribute, title }: Props) => {
  const { key, value, isKeyHighlighted, icd10code, keyTitle } = patientDetailAttribute;
  return (
    <div className="listitem">
      {isKeyHighlighted ? (
        <strong>{key}</strong>
      ) : (
        <span title={keyTitle ? keyTitle : ""}>{key}</span>
      )}
      {icd10code && <span title={"ICD10 Code"}>{icd10code}</span>}
      <strong title={title}>{value}</strong>
    </div>
  );
};
