import React, { useEffect, useState } from "react";
import {
  AssociatedPatientsList,
  UnassociatedPatientsList,
  PatientsListTab,
} from "./patients-list-container";
import { PATIENTS_LIST_TABS } from ".";

export const PatientsListContainer = () => {
  const [selectedTab, setSelectedTab] = useState(PATIENTS_LIST_TABS.ASSOCIATED);
  const [component, setComponent] = useState(<></>);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PATIENTS_LIST_TABS.ASSOCIATED:
        res = <AssociatedPatientsList />;
        break;
      case PATIENTS_LIST_TABS.UNASSOCIATED:
        res = <UnassociatedPatientsList />;
        break;
    }
    setComponent(res);
  }, [selectedTab]);

  return (
    <>
      <PatientsListTab {...{ onChange, selectedTab }} />
      {component}
    </>
  );
};
